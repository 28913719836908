import { Link } from 'react-router-dom';
import badgeIcon from '../assets/icons/badge.svg';
import certIcon from '../assets/icons/push-to-linkedin-icon.svg';
import emailIcon from '../assets/icons/email.png';
import linkedinIcon from '../assets/icons/linkedin.svg';
import codqQualityIcon from '../assets/icons/quality-badge.svg';
import '../styles/profile.css';
import '../styles/root.css';

import editIcon from '../assets/icons/edit.svg';


function getTopStats(badgeStats) {
    let top10 = badgeStats['top_10'] || 0;
    let top20 = badgeStats['top_20'] || 0;
    let top30 = badgeStats['top_30'] || 0;
    return [top10, top20 + top30];
}

const displayBadgeStats = (badgeStats, key) => {
    return <div className='badge-stats'>
        <table className='small_table'>
            <thead>
                <tr>
                    <th>{key}</th>
                    <th><img src={badgeIcon} title='Ranking' alt="Ranking" className='icon-td' /></th>
                    {key === 'Top Langs' ? <th><img src={codqQualityIcon} title="Code Quality" alt="Code Quality" className='icon-td' /></th> : <th></th>}
                </tr>
            </thead>
            <tbody>
                {badgeStats.map((badge, index) => {
                    const [top_10_thrp, top_30_thrp] = getTopStats(badge['thrp_stats']);
                    const [top_10_quality, top_30_quality] = getTopStats(badge['qual_stats']);
                    return <tr key={index}>
                        <td>{badge['_key']}</td>
                        <td>{top_10_thrp + top_30_thrp}</td>
                        {key == 'Top Langs' ? <td>{top_10_quality + top_30_quality}</td> : <td></td>}
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

const displayTeamSummary = (teamSummary) => {
    return <div>
        <label>Team Size: {teamSummary['user_count']} 👤</label>
        {displayBadgeStats(teamSummary['langs'], 'Top Langs')}
        <br />
        {displayBadgeStats(teamSummary['skills'], 'Top Skills')}
    </div>
}



const AccountInfoDisplay = ({ accountInfo }) => {
    return accountInfo ? (
        <div className='profile-account-container'>
            <div className='profile-name-card'>
                <div className="profile-account-info">
                    <div className="profile-account-name">{accountInfo.first_name} {accountInfo.last_name}</div>
                    {accountInfo.team_summary ? <div className="profile-account-team">{displayTeamSummary(accountInfo.team_summary)}</div> :
                        <div>
                            <div className="profile-account-text">
                                {accountInfo.country && (<div>{accountInfo.state}, {accountInfo.country}</div>)}
                            </div>
                            <div className="profile-account-contact">
                                {accountInfo.personal_email && (
                                    <a href={`mailto:${accountInfo.personal_email}`}>
                                        <img src={emailIcon} alt="email" className='icon' />
                                    </a>
                                )}
                                {accountInfo.linkedin_url && (
                                    <a href={accountInfo.linkedin_url} target='_blank' rel="noreferrer">
                                        <img src={linkedinIcon} alt="linkedin" className='icon' />
                                    </a>
                                )}
                            </div>
                        </div>
                    }
                </div>
                {accountInfo.is_self && <div className='profile-account-edit'>
                    <Link to='/account'><img src={editIcon} alt="edit_profile" className='icon-btn' title='Edit Profile' /></Link>
                    <Link to='/summary'><img src={certIcon} alt="Add to LinkedIn" className='icon-btn' title='Add to Linkedin' /></Link>
                </div>}
            </div>
            {accountInfo.about_me && <div className='profile-account-about-container'>
                <div className='profile-account-about-title'>About</div>
                <div className="profile-account-about-text">{accountInfo.about_me}</div>
            </div>}
        </div>
    ) : null;
}
export default AccountInfoDisplay;