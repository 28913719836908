import '../styles/checkboxgroup.css';
import { toTitleCase } from '../utils/Utils';
const CheckboxGroup = ({ data, groupName, displayText, onChange, maxSelection = -1 }) => {
    const currNumChecked = Object.values(data).filter((value) => value === 1).length;
    if (maxSelection > 0 && currNumChecked >= maxSelection) {
        return (
            <div className='checkbox-group-container'>
                <h3>{displayText}</h3>
                <div className="checkbox-group">
                    {Object.keys(data).map((key) => (
                        <div key={key}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={data[key] === 1}
                                    onChange={() => onChange(groupName, key)}
                                    disabled={data[key] === 0}
                                />
                                {key}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <div className='checkbox-group-container'>
            <h3>{displayText}</h3>
            <div className="checkbox-group">
                {Object.keys(data).map((key) => (
                    <div key={key}>
                        <label>
                            <input
                                type="checkbox"
                                checked={data[key] === 1}
                                onChange={() => onChange(groupName, key)}
                            />
                            {key}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckboxGroup;