// Layout.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/profile.css';
import '../styles/root.css';
import Background from './Background';
import Footer from './Footer';
import Header from './Header';
import Tab from './Tab';

const EngLayout = ({ children, isLoggedIn }) => {
  const currPage = window.location.pathname;
  const navigate = useNavigate();


  function goto(path) {
    navigate(path);
  }

  return (
    <div className="content">
      <Header />
      <Background />
      <nav>
        <div className='eng_layout'>
          <Tab name="1. About Me" isActive={currPage === '/account'} onClick={() => goto('/account')} />
          {isLoggedIn && <>
            <Tab name="2. My Experience" isActive={currPage === '/experience'} onClick={() => goto('/experience')} />
            <Tab name="3. My Profile" isActive={currPage === '/profile'} onClick={() => goto('/profile')} />
            <Tab name="4. My Job Apps." isActive={currPage === '/myjobs'} onClick={() => goto('/myjobs')} />
          </>}
        </div>
      </nav >
      <main>
        {children}
      </main>
      <Footer />
    </div >
  );
}

export default EngLayout;
