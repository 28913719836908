import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import badgeIcon from '../assets/icons/badge.svg';
import linkedinIcon from '../assets/icons/linkedin.svg';
import codqQualityIcon from '../assets/icons/quality-badge.svg';
import '../styles/root.css';
import '../styles/search.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import ProfileTab from './ProfileTab';
import { trimString } from '../utils/Utils';

const candidateApi = '/api/v1/org/jobs/candidate';

const Search = ({ jobCandidates, jobHash, baselines, baselineAccountInfos, toggleFullScreen, handleStatusChange, recipeSkillsAndLangs }) => {
    const [expandedRow, setExpandedRow] = useState(null);
    const [currCandidateProfile, setCurrCandidateProfile] = useState(null);
    const [currCandidateEmailHash, setCurrCandidateEmailHash] = useState(null);
    const navigate = useNavigate();

    const toggleExpand = (index) => {
        const expIndex = expandedRow === index ? null : index;
        setExpandedRow(expIndex);
        toggleFullScreen();
    };

    const loadProfile = async () => {
        const response = await fetchAPIWithToken(navigate, `${candidateApi}?job_hash=${jobHash}&c_hash=${currCandidateEmailHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        setCurrCandidateProfile(data);
    }

    useEffect(() => {
        if (expandedRow === null || !jobCandidates[expandedRow]) {
            return;
        }
        setCurrCandidateEmailHash(jobCandidates[expandedRow].candidate_email_hash);
    }, [expandedRow]);

    useEffect(() => {
        if (currCandidateEmailHash === null) {
            return;
        }
        loadProfile();
    }, [currCandidateEmailHash]);

    const setStatus = async (index, rating) => {
        handleStatusChange(index, rating);
    }

    const displaySkills = (title, skills, skillBadges, qualityBadges, max) => {
        const skillsToShow = skills.slice(0, max); // Limit the skills to 'max'

        return (
            <div>
                <h2 className="no_margin_h2">{title}</h2>
                <div className={`skills-container ${max > 3 ? "two-columns" : ""}`}>
                    {skillsToShow.map((skill, index) => (
                        <div key={index} className="skill_summary">
                            <b>{skill}</b>
                            {skillBadges && skillBadges[index] ? (
                                <img
                                    src={badgeIcon}
                                    title="Ranking"
                                    alt="Ranking"
                                    className="icon-td"
                                />
                            ) : null}
                            {qualityBadges && qualityBadges[index] ? (
                                <img
                                    src={codqQualityIcon}
                                    title="Code Quality"
                                    alt="Code Quality"
                                    className="icon-td"
                                />
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div className="job-candidates-list">
            {jobCandidates.map((row, index) => ((expandedRow === index || expandedRow === null) &&
                <div key={index} className="job-candidate-row">
                    <div className="candidate-header">
                        <h2 className="no_margin_h2">
                            {row.first_name} {row.last_name} {row.linkedin_url && <a className='no_margin_h2' href={row.linkedin_url} target='_blank' rel="noreferrer">
                                <img src={linkedinIcon} alt="linkedin" className='icon' />
                            </a>}
                        </h2>
                        {row.personal_email ? (
                            <a href={`mailto:${row.personal_email}`}>
                                <h4 className="no_margin_h2">
                                    {trimString(row.personal_email, 30)}
                                </h4>
                            </a>
                        ) : <h4 className="no_margin_h2"></h4>}
                        <h4 className="no_margin_h2">
                            {row.country && (<div>{row.state}, {row.country}</div>)}
                        </h4>
                        <div className="four_small_buttons">
                            <button onClick={() => setStatus(index, 1)} className={row.candidate_status == 1 ? 'selected' : ''} >👍</button>
                            <button onClick={() => setStatus(index, 0)} className={row.candidate_status == 0 ? 'selected' : ''} title="TBD">❓</button>
                            <button onClick={() => setStatus(index, -1)} className={row.candidate_status == -1 ? 'selected' : ''}>👎</button>
                            <button onClick={() => toggleExpand(index)} className="">{expandedRow === index ? '↩' : '📊'}</button>
                        </div>
                    </div>
                    {expandedRow === index ? (
                        currCandidateProfile &&
                        <ProfileTab profileData={currCandidateProfile['display_profile']} accountInfo={currCandidateProfile['account_info']} baselines={baselines} baselineAccountInfos={baselineAccountInfos} recipeSkillsAndLangs={recipeSkillsAndLangs} />
                    ) : <div className="skill_container">
                        {displaySkills("Languages", row.search_summary.lang_names, row.search_summary.lang_badges, row.search_summary.lang_quality_badges, 3)}
                        {displaySkills("Skills", row.search_summary.skill_names, row.search_summary.skill_badges, row.search_summary.skill_quality_badges, 5)}
                    </div>}
                </div>
            ))}
        </div>
    );
};

export default Search;
