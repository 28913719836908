import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BlurredContent from '../components/BlurredContent';
import EngLayout from '../components/EngLayout';
import Layout from '../components/Layout';
import ProfileTab from '../components/ProfileTab';
import { DomainSkills } from '../components/Skills';
import '../styles/profile.css';
import '../styles/root.css';
import { fetchAPI, fetchAPIWithToken } from '../utils/ApiUtils';
import { isTokenExpired } from '../utils/CheckToken';


const profileApiPrefix = '/api/v1/user/profile';
const pubProfileApiPrefix = '/api/v1/user/pubprofile';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProfilePage = () => {
  const query = useQuery();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginChecked, setIsLoginChecked] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [skillProfileStatus, setSkillProfileStatus] = useState(0);
  const [anonymizedProfile, setAnonymizedProfile] = useState(false);
  const [tokenCount, setTokenCount] = useState(0);
  const navigate = useNavigate();
  const profileId = query.get('id') || '';
  const useToken = (query.get('useToken') || 'false') === 'true';


  useEffect(() => {
    setIsLoggedIn(!isTokenExpired());
    setIsLoginChecked(true);
  }, []);

  useEffect(() => {
    if (isLoginChecked) {
      fetchAccount();
    }
  }, [isLoginChecked]);

  async function fetchAccount() {
    let response;
    if (!isLoggedIn) {
      response = await fetchAPI(`${pubProfileApiPrefix}?profile_id=${profileId}`, 'GET', {});
    } else {
      if (useToken) {
        const data = { use_token: useToken }
        response = await fetchAPIWithToken(navigate, `${profileApiPrefix}?profile_id=${profileId}`, 'POST', data);
      } else {
        response = await fetchAPIWithToken(navigate, `${profileApiPrefix}?profile_id=${profileId}`, 'GET', {});
      }
    }
    if (response.status !== 200) {
      console.error(response);
      return;
    }
    const data = await response.json();
    setProfileData(data['display_profile']);
    setInfoData(data['account_info']);
    setSkillProfileStatus(data['skill_profile_status']);
    setAnonymizedProfile(data['anonymized'])
    setTokenCount(data['token_count']);
  }

  const displayViewProfMessage = () => {
    if (skillProfileStatus === 2 && anonymizedProfile) {
      let message;
      if (isLoggedIn) {
        message = (<>
          <h3>
            <a href={`/profile?id=${profileId}&useToken=true`}>Use your modelteam token</a> to view full profile
          </h3>
          <h3>
            Your token balance: {tokenCount}
          </h3>
          {tokenCount <= 0 ? <h4>To get more tokens, please refer your friends to modelteam.ai or signup for enterprise package.</h4> : null}
        </>);
      } else {
        message = <h3><a href={`/login?redirect=${encodeURIComponent(`/profile?id=${profileId}`)}`}>Signup / Login</a> to View Full Profile</h3>
      }
      return (
        <BlurredContent message={message}>
          <DomainSkills skillsData={[profileData.skills[0], profileData.skills[1], profileData.skills[0]]} numYears={3} />
        </BlurredContent>);
    } else {
      return null;
    }
  }

  if (!isLoginChecked) {
    return <div>Loading...</div>;
  }

  if (!profileData || !infoData) {
    if (!profileId && isLoggedIn) {
      // Show user's own profile
      return (
        <EngLayout isLoggedIn={isLoggedIn}>
          <h1>⌛ Profile Not Present.. Try after sometime..</h1>
        </EngLayout>);
    } else {
      return (
        <Layout>
          <h1>⌛ Profile Not Present.. Try after sometime..</h1>
        </Layout>);
    }
  }
  if (!profileId && isLoggedIn) {
    // Show user's own profile
    return (
      <EngLayout isLoggedIn={isLoggedIn}>
        {skillProfileStatus === 2 ? <ProfileTab profileData={profileData} accountInfo={infoData} /> : <h2>⌛ We are still processing the profile...</h2>}
        {displayViewProfMessage()}
      </EngLayout>
    );
  } else {
    return (
      <Layout>
        {skillProfileStatus === 2 ? <ProfileTab profileData={profileData} accountInfo={infoData} /> : <h2>⌛ We are still processing the profile...</h2>}
        {displayViewProfMessage()}
      </Layout>
    );
  }
}

export default ProfilePage;