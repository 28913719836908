import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import '../styles/copyToCB.css';
import '../styles/root.css';

const CopyTextToClipBoard = ({ urlText, isUrl = true }) => {
    const [tooltipMessage, setTooltipMessage] = useState('Copy to clipboard');

    const handleCopy = () => {
        setTooltipMessage('Copied!');
        setTimeout(() => setTooltipMessage('Copy to clipboard'), 2000); // Reset the tooltip message after 2 seconds
    };

    return (<div className='copytext'>
        {isUrl ? <a href={urlText} target='_blank' rel='noreferrer'>
            {urlText}
        </a> : <span>{urlText}</span>}
        <div>
            <CopyToClipboard text={urlText} onCopy={handleCopy}>
                <button type="button" data-tip data-for="copyTooltip" className='copy-button'>
                    📋
                </button>
            </CopyToClipboard>
            <ReactTooltip id="copyTooltip" place="top" effect="solid" getContent={() => tooltipMessage} />
        </div>
    </div>);
}

export default CopyTextToClipBoard;
