// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CopyTextToClipBoard from '../components/CopyToClipBoard';
import EngLayout from '../components/EngLayout';
import '../styles/account.css';
import '../styles/jobs.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isTokenExpired } from '../utils/CheckToken';
import { formatDate } from '../utils/DateTimeUtils';

const jobsApiPrefix = '/api/v1/work/jobs';
const jobApiPrefix = '/api/v1/work/job';
const skillApiPrefix = '/api/v1/work/skills'

const Experience = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [workEmailHash, setWorkEmailHash] = useState('');
    const [userEmailHash, setUserEmailHash] = useState(localStorage.getItem('email_hash'));
    const [jobs, setJobs] = useState([]);
    const [selectedJobIndex, setSelectedJobIndex] = useState(-1);
    const [addNew, setAddNew] = useState(false);
    const [reloadJobs, setReloadJobs] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [showDeletedJobs, setShowDeletedJobs] = useState(false);

    const handleCheckboxChange = (event) => {
        setShowDeletedJobs(event.target.checked);
    };

    const [currJob, setCurrJob] = useState({
        user_email_hash: '',
        work_email_hash: '',
        work_email: '',
        current_company: '',
        deleted: false,
        skill_profile_status: 0
    });

    const [skillProfiles, setSkillProfiles] = useState([]);
    const [selectedSkillProfile, setSelectedSkillProfile] = useState('');
    const [currSkillProfile, setCurrSkillProfile] = useState({
        id: 0,
        name: '',
        repo_list: '',
        created_at: '',
        deleted: false,
        display_name: ''
    });

    const resetSkillState = () => {
        setSkillProfiles([]);
        setSelectedSkillProfile('');
        setCurrSkillProfile({
            id: 0,
            name: '',
            repo_list: '',
            created_at: '',
            deleted: false,
            display_name: ''
        });
        const profile = document.getElementById('profile')
        if (profile) {
            profile.value = '';
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrJob(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    function pickCurrJob(jobs) {
        let noJob = false;
        if (!workEmailHash || (currJob.deleted && !showDeletedJobs)) {
            noJob = true;
            for (let i = 0; i < jobs.length; i++) {
                if (!jobs[i].deleted || showDeletedJobs) {
                    setSelectedJobIndex(i);
                    setCurrJob(jobs[i]);
                    setWorkEmailHash(jobs[i].work_email_hash);
                    noJob = false;
                    break;
                }
            }
        }
        if (noJob) {
            setCurrJob({
                user_email_hash: userEmailHash,
                work_email_hash: '',
                work_email: '',
                current_company: '',
                deleted: false,
                skill_profile_status: 0
            });
            setSelectedJobIndex(-1);
            setWorkEmailHash('');
        }
    }


    useEffect(() => {
        setIsLoggedIn(!isTokenExpired());
        setIsLoginChecked(true);
    }, []);

    useEffect(() => {
        pickCurrJob(jobs);
    }, [showDeletedJobs]);

    useEffect(() => {
        pickCurrJob(jobs);
    }, [jobs]);

    useEffect(() => {
        loadJobs();
        loadSkillProfiles();
        currJob['user_email_hash'] = userEmailHash;
    }, [navigate, reloadJobs]);

    useEffect(() => {
        resetSkillState();
        loadJob();
        loadSkillProfiles();
    }, [workEmailHash]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/login?redirect=/experience`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadJob = async () => {
        if (workEmailHash) {
            const response = await fetchAPIWithToken(navigate, `${jobApiPrefix}?work_email_hash=${workEmailHash}`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const data = await response.json();
            setCurrJob(data);
        } else {
            setCurrJob({
                user_email_hash: userEmailHash,
                work_email_hash: '',
                work_email: '',
                current_company: '',
                deleted: false,
                skill_profile_status: 0
            });
        }
    }

    const loadJobs = async () => {
        if (!userEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${jobsApiPrefix}?user_email_hash=${userEmailHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setJobs(data);
        }
    };

    const loadSkillProfiles = async () => {
        if (!workEmailHash || !userEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${skillApiPrefix}?work_email_hash=${workEmailHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        setSkillProfiles(data);
        if (data.length > 0) {
            setSelectedSkillProfile(data[0].id);
            setCurrSkillProfile(data[0]);
        }
        const profile = document.getElementById('profile')
        if (profile) {
            profile.value = '';
        }
    };

    function validateJob() {
        if (currJob.work_email && currJob.current_company) {
            return true;
        }
        return false;
    }
    const createOrUpdateJob = async () => {
        if (validateJob()) {
            if (workEmailHash) {
                console.log('Updating Job');
                const response = await fetchAPIWithToken(navigate, jobApiPrefix, 'PUT', currJob);
                if (response.status === 200) {
                    const data = await response.json();
                    setCurrJob(data);
                    setIsEditing(false);
                    setReloadJobs(!reloadJobs);
                }
                else {
                    console.error(response);
                }
            } else {
                const response = await fetchAPIWithToken(navigate, jobApiPrefix, 'POST', currJob);
                if (response.status === 201) {
                    const data = await response.json();
                    setWorkEmailHash(data.work_email_hash);
                    setIsEditing(false);
                    setAddNew(false);
                    setReloadJobs(!reloadJobs);
                } else {
                    console.error(response);
                }
            }
        }
        else {
            alert('Please fill all the required fields');
        }
    };

    const toggleDelete = async () => {
        if (workEmailHash) {
            if (!currJob.deleted) {
                if (!window.confirm('Are you sure you want to delete this experience?')) {
                    return;
                }
            }
            currJob.deleted = !currJob.deleted;
            await createOrUpdateJob();
        }
    }

    const toggleDeleteSkillProfile = async () => {
        if (selectedSkillProfile) {
            if (!currSkillProfile.deleted) {
                if (!window.confirm('Are you sure you want to delete this file from your profile?')) {
                    return;
                }
            }
            currSkillProfile.deleted = !currSkillProfile.deleted;
            await deleteSkillProfile();
            await loadSkillProfiles();
        }
    }

    const deleteSkillProfile = async () => {
        const response = await fetchAPIWithToken(navigate, skillApiPrefix, 'PUT', currSkillProfile);
        if (response.status === 200) {
            const data = await response.json();
            setSelectedSkillProfile(data.id);
            setCurrSkillProfile(data);
        }
        else {
            console.error(response);
        }
    }

    const uploadSkillFile = async () => {
        const fileInput = document.getElementById('profile');
        if (fileInput && fileInput.files.length > 0) {
            if (!window.confirm('Behavior:\nFor New repo - Add to new profile\nFor Existing repo - Replace previous profile\nAre you sure you want to proceed?')) {
                return;
            }
            const fileName = fileInput.files[0].name;
            // TODO: remove mt_profile_ after migration
            if (fileName.endsWith('.json.gz') && (fileName.startsWith('mt_stats_') || fileName.startsWith('mt_profile_'))) {
                const formData = new FormData();
                formData.append('file', fileInput.files[0]);
                formData.append('work_email_hash', workEmailHash);
                try {
                    const response = await fetchAPIWithToken(navigate, skillApiPrefix, 'POST', formData, true, true);
                    if (response.status !== 201) {
                        const data = await response.json();
                        console.error(data);
                        alert('Error uploading file: ' + data.error);
                        return;
                    }
                    loadSkillProfiles();
                    alert('File uploaded successfully! Your user profile will be updated shortly in "About Me" section.');
                    fileInput.value = '';
                } catch (error) {
                    console.log(error);
                    alert('Error uploading file');
                }
            } else {
                console.log('Invalid file format');
                alert('Invalid file format');
            }
        } else {
            alert('No file selected.');
            console.log("No file selected.");
        }
    }

    const handleSkillChange = (event) => {
        const { value } = event.target;
        setSelectedSkillProfile(value);
        const profile = skillProfiles.find(profile => profile.id === parseInt(value));
        setCurrSkillProfile(profile);
    }

    const displaySkillProfile = () => {
        if (skillProfiles.length > 0) {
            return <div>
                <div className='two_columns'>
                    <div className='whoami'>
                        <select name='skill_profiles' id='skill_profiles' size={5} onChange={handleSkillChange} value={selectedSkillProfile}>
                            {skillProfiles.map((skillProfile, index) => (
                                <option key={index} value={skillProfile.id}>{skillProfile.display_name + (skillProfile.deleted ? ' 🗑' : '')}</option>
                            ))}
                        </select>
                    </div>
                    {selectedSkillProfile &&
                        <div className='whoami'>
                            <label>{'Created on: ' + formatDate(currSkillProfile.created_at)}</label>
                            <label>{'Repo List: ' + currSkillProfile.repo_list}</label>
                            <label>{'Status: ' + (currSkillProfile.deleted ? 'Deleted' : 'Active')}</label>
                            <button className={currSkillProfile.deleted ? '' : 'red'} onClick={toggleDeleteSkillProfile}>{currSkillProfile.deleted ? 'Add File Back' : 'Delete File'}</button>
                        </div>
                    }
                </div>
            </div>
        }
    }

    const displayJob = () => {
        if (addNew || workEmailHash) {
            const readOnly = addNew ? false : !isEditing;
            return <div className='right-pane-jobs'>
                <div className='whoami'>
                    <label htmlFor='current_company'>Current Company</label>
                    <input type='text' id='current_company' name='current_company' placeholder='Company/School' readOnly={readOnly} value={currJob.current_company} onChange={handleChange} />
                    <label htmlFor='work_email'>Git Id (Can't Edit Once Created)</label>
                    <input type='email' id='work_email' name='work_email' placeholder='Git Id in the git commit logs' value={currJob.work_email} readOnly={!addNew} onChange={handleChange} />
                    {!readOnly &&
                        <div>
                            <span>
                                You can get <strong>Git Id</strong> by running <code>git log</code> command in your git repo as shown below. Text between <code>&lt;&gt;</code> is the <strong>Git Id</strong>, e.g., <code>Author: XYZ &lt;<strong>userXYZ@org.ai</strong>&gt;</code>
                            </span>
                            <pre>
                                git log | grep XYZ | head -2<br />
                                Author: XYZ &lt;userXYZ@org.ai&gt;<br />
                                Author: XYZ &lt;1234567+XYZ@users.noreply.github.com&gt;<br />
                            </pre>
                        </div>
                    }
                    <div className='col_buttons'>
                        {(!addNew && readOnly) && <button className={currJob.deleted ? '' : 'red'} onClick={toggleDelete}>{currJob.deleted ? 'Add Back' : 'Delete'}</button>}
                        {(!currJob.deleted && !readOnly) &&
                            <button id="cancel" onClick={() => {
                                setIsEditing(false);
                                loadJob();
                            }}>Cancel</button>
                        }
                        {(!currJob.deleted && !readOnly) &&
                            <button id="submit" onClick={createOrUpdateJob}>Submit</button>
                        }
                        {(!currJob.deleted && readOnly) &&
                            <button id="edit" onClick={() => setIsEditing(true)}>Edit</button>
                        }
                    </div>
                    {addNew ? <p>
                        <b>After Adding your job*, a verification key will be generated for you to use.</b>
                        <br /><br />
                        <b>*</b>We recommend adding only the experiences for which you have access to code repo :)
                    </p>
                        :
                        <div className='file_upload'>
                            <p>
                                Follow the instructions in our <a href='https://github.com/modelteam-ai/modelteam.ai/blob/main/README.md' target='_blank' rel="noreferrer">Git README</a> to extract the stats that will be used by our AI system to build your profile.
                            </p>
                            <b>Verification Key</b>
                            <CopyTextToClipBoard urlText={workEmailHash} isUrl={false} />
                            <label><b>Files (mt_stats_****.json.gz)</b></label>
                            {displaySkillProfile()}
                            <div className='whoami'>
                                <input id='profile' type='file' name='profile' accept='.gz' onChange={handleFileChange} />
                                <button onClick={uploadSkillFile} disabled={currJob.deleted || !fileSelected} className='small_button'>Upload File</button>
                            </div>
                        </div>
                    }
                </div>
            </div >
        } else {
            return <div className='right-pane-jobs'></div>
        }
    }

    const handleJobClick = (index, workEmailHash) => {
        setSelectedJobIndex(index);
        setWorkEmailHash(workEmailHash)
        setAddNew(false);
    }

    const handleAddNew = () => {
        setSelectedJobIndex(-1);
        setWorkEmailHash('');
        setAddNew(true);
    }

    const handleFileChange = (event) => {
        setFileSelected(event.target.files.length > 0);
    };

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <EngLayout isLoggedIn={isLoggedIn}>
            <div className='outer-box-top'>
                <div className='left-pane'>
                    {jobs && <label>
                        <input
                            type="checkbox"
                            checked={showDeletedJobs}
                            onChange={handleCheckboxChange}
                        />
                        Show Deleted Experiences
                    </label>}
                    {jobs.map((job, index) => (
                        (showDeletedJobs || !job.deleted) ? (
                            <div name={index + "-" + selectedJobIndex} key={index} className={`job_div ${selectedJobIndex === index ? 'selected' : ''}`} onClick={() => handleJobClick(index, job.work_email_hash)}>
                                <div>
                                    <b>{job.work_email}</b>{job.deleted ? ' 🗑' : ''}
                                    <br />
                                    {job.current_company}
                                </div>
                            </div>
                        ) : null
                    ))}
                    <div onClick={() => handleAddNew()} className={`job_div ${addNew ? 'selected' : ''}`}>
                        <h4>Add New +</h4>
                    </div>
                </div>
                {displayJob()}
            </div>
        </EngLayout>
    );
};

export default Experience;
