// Layout.jsx

import React from 'react';
import '../styles/root.css';
import '../styles/ftu-step.css';

const LogInLayout = ({ children }) => {

  return (
    <div className='login-container'>
        {children}
    </div>
  );
}

export default LogInLayout;
