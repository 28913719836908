// Layout.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/profile.css';
import '../styles/root.css';
import Footer from './Footer';
import Header from './Header';
import Tab from './Tab';


const OrgLayout = ({ children, isLoggedIn }) => {
  const currPage = window.location.pathname;
  const navigate = useNavigate();


  function goto(path) {
    navigate(path);
  }

  return (
    <div className="content">
      <Header />
      <nav>
        <div className='org_layout'>
          <Tab name="Company" isActive={currPage === '/org/account'} onClick={() => goto('/org/account')} />
          {isLoggedIn && <>
            <Tab name="Teams" isActive={currPage === '/org/teams'} onClick={() => goto('/org/teams')} />
            <Tab name="Analyze Teams" isActive={currPage === '/org/teamprof'} onClick={() => goto('/org/teamprof')} />
            <Tab name="Jobs" isActive={currPage === '/org/jobs' || currPage === '/org/copilot' || currPage === '/org/'} onClick={() => goto('/org/jobs')} />
          </>}
        </div>
      </nav >
      <main>
        {children}
      </main>
      <Footer />
    </div >
  );
}

export default OrgLayout;
