// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CopyURLToClipBoard from '../components/CopyToClipBoard';
import OrgLayout from '../components/OrgLayout';
import { frontendUrl } from '../configs/config';
import '../styles/account.css';
import '../styles/jobs.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isOrgTokenExpired, ORG_PREFIX } from '../utils/CheckToken';
import { formatDate } from '../utils/DateTimeUtils';
const jobsPrefix = '/api/v1/org/jobs';
const jobsApiPrefix = jobsPrefix + '/jobs';
const jobApiPrefix = jobsPrefix + '/job';
const candidatesPrefix = '/api/v1/org/jobs/candidates';
const teamPrefix = '/api/v1/org/team';
const teamsApiPrefix = teamPrefix + '/teams';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const OrgJobs = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [jobHash, setJobHash] = useState(sessionStorage.getItem(ORG_PREFIX + 'jobs_job_hash') || '');
    const [orgAccountUserEmailHash, setOrgAccountUserEmailHash] = useState(localStorage.getItem(ORG_PREFIX + 'email_hash'));
    const [jobs, setJobs] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [reloadJobs, setReloadJobs] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const query = useQuery();
    const [teams, setTeams] = useState([]);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(-1);
    const [teamHash, setTeamHash] = useState(sessionStorage.getItem(ORG_PREFIX + 'jobs_team_hash') || '');
    const [currJob, setCurrJob] = useState({
        org_email_hash: '',
        job_name: '',
        team_hash: teamHash,
        job_hash: '',
        skill_profile_status: 0,
        deleted: false,
        updated_at: '',
        recipe: '',
        job_link: '',
        ats_link: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrJob(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        setIsLoggedIn(!isOrgTokenExpired());
        setIsLoginChecked(true);
    }, []);

    useEffect(() => {
        loadTeams();
    }, [navigate]);

    useEffect(() => {
        if (reloadJobs) {
            loadJobs();
            setReloadJobs(false);
        }
        currJob['org_email_hash'] = orgAccountUserEmailHash;
    }, [reloadJobs]);


    useEffect(() => {
        setJobHash('');
        setJobs([]);
        if (!teamHash) {
            setCurrJob({
                org_email_hash: orgAccountUserEmailHash,
                job_name: '',
                team_hash: teamHash,
                job_hash: '',
                skill_profile_status: 0,
                deleted: false,
                updated_at: '',
                recipe: '',
                job_link: '',
                ats_link: '',
            });
            return;
        }
        setAddNew(false);
        setIsEditing(false);
        sessionStorage.setItem(ORG_PREFIX + 'jobs_team_hash', teamHash);
        loadJobs();
        currJob['org_email_hash'] = orgAccountUserEmailHash;
    }, [teamHash]);

    useEffect(() => {
        if (jobHash) {
            setAddNew(false);
            setIsEditing(false);
            sessionStorage.setItem(ORG_PREFIX + 'jobs_job_hash', jobHash);
        }
    }, [jobHash]);

    useEffect(() => {
        if (!jobHash || jobHash === '' || jobs.length === 0) {
            return;
        }
        const job = jobs.find(job => job.job_hash === jobHash);
        if (job) {
            setCurrJob(job);
        } else {
            setCurrJob({
                org_email_hash: orgAccountUserEmailHash,
                job_name: '',
                team_hash: teamHash,
                job_hash: '',
                skill_profile_status: 0,
                deleted: false,
                updated_at: '',
                recipe: '',
                job_link: '',
                ats_link: '',
            });
        }

    }, [jobHash, jobs]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/org/login?redirect=/org/jobs`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadJob = async () => {
        if (jobHash) {
            const response = await fetchAPIWithToken(navigate, `${jobApiPrefix}?job_hash=${jobHash}`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const data = await response.json();
            setCurrJob(data);
        } else {
            setCurrJob({
                org_email_hash: orgAccountUserEmailHash,
                job_name: '',
                team_hash: teamHash,
                job_hash: '',
                skill_profile_status: 0,
                deleted: false,
                updated_at: '',
                recipe: '',
                job_link: '',
                ats_link: '',
            });
        }
    }

    const loadTeams = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamsApiPrefix}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setTeams(data);
            if (selectedTeamIndex === -1 && !teamHash) {
                setSelectedTeamIndex(0);
                setTeamHash(data[0].team_hash);
            } else if (teamHash) {
                const index = data.findIndex(team => team.team_hash === teamHash);
                if (index !== -1) {
                    setSelectedTeamIndex(index);
                    setTeamHash(teamHash);
                } else {
                    setSelectedTeamIndex(0);
                    setTeamHash(data[0].team_hash);
                }
            }
        } else {
            setTeams([]);
            setTeamHash(null);
        }
    };

    const loadJobs = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${jobsApiPrefix}?team_hash=${teamHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setAddNew(false);
            setJobs(data);
            if (jobHash === '' || data.findIndex(job => job.job_hash === jobHash) === -1) {
                setJobHash(data[0].job_hash);
            } else if (jobHash) {
                const job = data.find(job => job.job_hash === jobHash);
                if (job) {
                    setJobHash(jobHash);
                } else {
                    setJobHash(data[0].job_hash);
                }
            }
        } else {
            setJobHash('');
            setJobs([]);
            setCurrJob({
                org_email_hash: orgAccountUserEmailHash,
                job_name: '',
                team_hash: teamHash,
                job_hash: '',
                skill_profile_status: 0,
                deleted: false,
                updated_at: '',
                recipe: '',
                job_link: '',
                ats_link: '',
            });
        }
    };

    function validateJob() {
        if (currJob.job_name && currJob.job_link) {
            return true;
        }
        return false;
    }

    const deleteJob = async () => {
        if (jobHash) {
            if (!currJob.deleted) {
                if (!window.confirm('Are you sure you want to delete this job?. This can\'t be undone.')) {
                    return;
                }
            }
            const response = await fetchAPIWithToken(navigate, jobApiPrefix + '?job_hash=' + jobHash, 'DELETE', currJob);
            if (response.status === 200) {
                setJobHash('');
                setReloadJobs(true);
            } else {
                console.error(response);
            }
        }
    }

    const createOrUpdateJob = async () => {
        if (validateJob()) {
            if (!jobHash) {
                try {
                    const response = await fetchAPIWithToken(navigate, jobApiPrefix, 'POST', currJob, true);
                    if (response.status !== 201) {
                        console.error(response);
                        alert('Error creating Job');
                        return;
                    }
                    const data = await response.json();
                    setJobHash(data.job_hash);
                    setCurrJob(data);
                    setIsEditing(false);
                    setAddNew(false);
                    setReloadJobs(true);
                } catch (error) {
                    console.error(error);
                    alert('Error creating Job');
                }
            } else {
                // Update
                try {
                    const response = await fetchAPIWithToken(navigate, jobApiPrefix, 'PUT', currJob, true);
                    if (response.status !== 201) {
                        console.error(response);
                        alert('Error updating job');
                        return;
                    }
                    setReloadJobs(true);
                    setIsEditing(false);
                } catch (error) {
                    console.error(error);
                    alert('Error updating job');
                }
            }
        } else {
            alert('Please fill all fields');
        }
    }

    const uploadCandidates = async () => {
        const fileInput = document.getElementById('profile');
        const fileName = fileInput.files[0].name;
        if (fileName.endsWith('.csv')) {
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('job_hash', jobHash);
            try {
                const response = await fetchAPIWithToken(navigate, jobsPrefix + '/candidates', 'POST', formData, true, true);
                if (response.status !== 201) {
                    console.error(response);
                    alert('Error uploading candidates');
                    return;
                }
                const data = await response.json();
                alert(data.message);
                fileInput.value = '';
            } catch (error) {
                console.error(error);
                alert('Error uploading file');
            }
        } else {
            console.error('Invalid file format');
            alert('Invalid file format');
        }
    }

    const downloadCandidates = async () => {
        if (jobHash) {
            const response = await fetchAPIWithToken(navigate, candidatesPrefix + '/download?job_hash=' + jobHash, 'GET', {});
            if (response.status !== 200) {
                alert('Error downloading candidates');
                console.error(response);
                return;
            }
            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `candidates_${jobHash}.csv`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }

    const searchCandidates = async () => {
        const args = {
            job_hash: jobHash,
            team_hash: teamHash,
            job_name: encodeURIComponent(currJob.job_name),
            team_name: encodeURIComponent(teams[selectedTeamIndex].team_name),
        }
        const argStr = Object.keys(args).map((key) => key + '=' + args[key]).join('&');
        navigate('/org/copilot?' + argStr)
    }

    const displayJob = () => {
        if (addNew || jobHash) {

            return <div className='right-pane-jobs'>
                <div className='whoami'>
                    <label htmlFor='job_name'>Job Name (Cannot be modified after creation)</label>
                    <input type='text' id='job_name' name='job_name' placeholder='Cannot be modified after creation' value={currJob.job_name} readOnly={!addNew} onChange={handleChange} />
                    <label htmlFor='job_link'>Public Job Posting Link (Will be visible to the candidates)</label>
                    <input type='text' id='job_link' name='job_link' placeholder='Job Link' value={currJob.job_link ? currJob.job_link : ''} readOnly={!isEditing} onChange={handleChange} />
                    <label htmlFor='ats_link'>ATS (Applicant Tracking System) Link</label>
                    <input type='text' id='ats_link' name='ats_link' placeholder='ATS Link' value={currJob.ats_link ? currJob.ats_link : ''} readOnly={!isEditing} onChange={handleChange} />
                    {addNew || isEditing ?
                        <div className='two_buttons'>
                            <button onClick={() => setIsEditing(false) && setAddNew(false)} className='small_button'>Cancel</button>
                            <button onClick={createOrUpdateJob} className='small_button'>Submit</button>
                        </div>
                        :
                        <div className='four_buttons'>
                            <button className='red' onClick={deleteJob}>Delete Job</button>
                            <button onClick={() => setIsEditing(true)} className='small_button'>Edit</button>
                            <button onClick={searchCandidates} className="small_button">Search Candidates</button>
                            <button onClick={downloadCandidates} className="small_button">Download Candidates</button>
                        </div>
                    }
                </div>


                {/* <textarea rows={30} id='email_template' name='email_template' placeholder='Email Template' value={emailTemplate} readOnly={true} /> */}
                {jobHash &&
                    <div>
                        <div className='whoami'>
                            <h3>Send the following link to all your candidates to access their modelteam profile</h3>
                            <CopyURLToClipBoard urlText={`${frontendUrl}/login?applyto=${currJob.job_hash}`} />
                            <h4>If your ATS system supports adding candidate id macro in the emails, please add 'atsid' parameter as shown below</h4>
                            <span>{frontendUrl}/login?applyto={currJob.job_hash}&atsid={'{{ candidate_id }}'}</span>
                        </div>
                    </div>
                }
            </div >
        } else {
            return <div className='right-pane-jobs'></div>
        }
    }

    const handleTeamSelectChange = (event) => {
        const index = event.target.value;
        if (!index || index === -1) {
            return;
        }
        setSelectedTeamIndex(index);
        setTeamHash(teams[index].team_hash);
    };

    const handleJobClick = (index, clickedJobHash) => {
        setJobHash(clickedJobHash)
        setAddNew(false);
        setIsEditing(false);
    }

    const handleAddNew = () => {
        setJobHash('');
        setAddNew(true);
        setIsEditing(true);
        setCurrJob({
            org_email_hash: orgAccountUserEmailHash,
            job_name: '',
            team_hash: teamHash,
            job_hash: '',
            skill_profile_status: 0,
            deleted: false,
            updated_at: '',
            recipe: '',
            job_link: '',
            ats_link: '',
        });
    }

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <OrgLayout isLoggedIn={isLoggedIn}>
            <div className='outer-box-top'>
                <div className='left-pane-teams'>
                    <div>
                        <label>Company/Team*</label>
                        <select onChange={handleTeamSelectChange} value={selectedTeamIndex}>
                            <option value="">Select a Team</option>
                            {teams.map((item, index) => (
                                <option key={index} value={index}>
                                    {item.team_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {jobs.map((job, index) => (
                        <div key={index} className={`job_div ${job.job_hash === jobHash ? 'selected' : ''}`} onClick={() => handleJobClick(index, job.job_hash)}>
                            <div>
                                <b>{job.job_name}</b>
                                <br />
                                <span>Updated on: {formatDate(job.updated_at)}</span>
                            </div>
                        </div>
                    ))}
                    <div onClick={handleAddNew} className={`job_div ${addNew ? 'selected' : ''}`}>
                        <label><b>Create New Job +</b></label>
                    </div>
                </div>
                {displayJob()}
            </div>
        </OrgLayout>
    );
};

export default OrgJobs;
