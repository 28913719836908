import React from 'react';
import Login from '../components/LogIn';
import '../styles/login.css';
import '../styles/root.css';


const OrgLogin = () => {
    return (
        <Login isOrg={true} />
    );
};

export default OrgLogin;
