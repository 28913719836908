import React, { useEffect, useState } from 'react';
import '../styles/mutex-lists.css';
import '../styles/root.css';

const MutexLists = ({ firstTitle, secondTitle, firstList, secondList, updateFirst, updateSecond, firstVal, secondVal }) => {
    const [firstDropdownValue, setFirstDropdownValue] = useState(firstVal);
    const [secondDropdownValue, setSecondDropdownValue] = useState(secondVal);

    useEffect(() => {
        setFirstDropdownValue(firstVal);
    }, [firstVal]);

    useEffect(() => {
        setSecondDropdownValue(secondVal);
    }, [secondVal]);

    const handleFirstDropdownChange = (event) => {
        setFirstDropdownValue(event.target.value);
        updateFirst(firstList.indexOf(event.target.value));
        setSecondDropdownValue(''); // Reset the second dropdown
    };

    const handleSecondDropdownChange = (event) => {
        setSecondDropdownValue(event.target.value);
        updateSecond(secondList.indexOf(event.target.value));
        setFirstDropdownValue(''); // Reset the first dropdown
    };

    return (
        <div className='mutex-lists'>
            <div>
                <label>{firstTitle}</label>
                <select
                    value={firstDropdownValue}
                    onChange={handleFirstDropdownChange}
                >
                    <option value="">Select an option</option>
                    {firstList.map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                    ))}
                </select>
            </div>

            <div>
                <label>{secondTitle}</label>
                <select
                    value={secondDropdownValue}
                    onChange={handleSecondDropdownChange}
                >
                    <option value="">Select an option</option>
                    {secondList.map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default MutexLists;
