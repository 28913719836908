// src/utils/ApiUtils.js
import { backendUrl } from '../configs/config';
import { isOrgTokenExpired, isTokenExpired } from './CheckToken';

function fetchAPI(api, method, body) {
    return fetchAPIWithToken(null, api, method, body, false, false);
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function fetchAPIWithToken(navigate, api, method, body, withToken = true, formdata = false) {
    var hdr = {}
    if (withToken) {
        const currPage = window.location.pathname;
        const redirect = currPage.includes('login') ? '' : '?redirect=' + currPage;
        const isOrg = currPage.includes('/org') || currPage.includes('/org/');
        if (isOrg) {
            if (isOrgTokenExpired(false)) {
                console.log('Org Token expired');
                navigate('/org/login' + redirect);
                return { status: 401 };
            }
        }
        else {
            if (isTokenExpired(false)) {
                console.log('Token expired');
                navigate('/login' + redirect);
                return { status: 401 };
            }
        }
        hdr['X-CSRF-TOKEN'] = getCookie('csrf_access_token');
    }
    if (!formdata) {
        hdr['Content-Type'] = 'application/json';
    }
    // accept gzip and json
    hdr['Accept-Encoding'] = 'gzip';
    const payload = {
        method: method,
        headers: hdr,
        credentials: 'include'
    }
    if (method !== 'GET') {
        if (formdata) {
            payload['body'] = body;
        } else {
            payload['body'] = JSON.stringify(body);
        }
    }
    return fetch(backendUrl + api, payload);
}

export { fetchAPI, fetchAPIWithToken };
