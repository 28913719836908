import moment from 'moment';

// Function to format date from 'yyyymm' to 'yyyy mmm'
function formatMonth(inputDate) {
    const parsedDate = moment(inputDate, 'YYYYMM');
    const formattedDate = parsedDate.format('YYYY MMM');
    return formattedDate;
}

function formatDateToMonYear(dateString) {
    if (!dateString) {
        return 'Present';
    }
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', timeZone: 'UTC' });
}

function formatDate(dateString) {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function backFillQtrs(quarters, values, numYears) {
    if (numYears < 0) {
        console.info('numYears < 0. so not backfilling');
        return { quarters, values };
    }
    const givenValues = {}
    for (let i = 0; i < quarters.length; i++) {
        givenValues[quarters[i]] = values[i];
    }
    const today = moment().endOf('quarter');
    const backFilledQuarters = []
    const backFilledValues = []
    const numYearsAgo = moment().subtract(numYears, 'years');
    for (let start = moment(numYearsAgo); start.isBefore(today); start.add(1, 'quarter')) {
        const qtr = start.format('YYYY') + 'Q' + start.quarter();
        backFilledQuarters.push(qtr);
        const value = givenValues[qtr] || 0;
        backFilledValues.push(value > 1 ? Math.round(value) : value);
    }
    return { quarters: backFilledQuarters, values: backFilledValues };
}

// Export the function to make it accessible from other files
export { backFillQtrs, formatDate, formatDateToMonYear, formatMonth };

