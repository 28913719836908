import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CopyURLToClipBoard from '../components/CopyToClipBoard';
import EngLayout from '../components/EngLayout';
import { frontendUrl } from '../configs/config';
import '../styles/root.css';
import { isTokenExpired } from '../utils/CheckToken';

const Refer = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [emailHash, setEmailHash] = useState('');
    const [referUrl, setReferUrl] = useState('');


    useEffect(() => {
        setIsLoggedIn(!isTokenExpired());
        setIsLoginChecked(true);
        const emailHash = localStorage.getItem('email_hash');
        setEmailHash(emailHash);
    }, []);


    useEffect(() => {
        setReferUrl(`${frontendUrl}/signup?ref=${emailHash}`);
    }, [emailHash]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/login?redirect=/refer`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);


    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <EngLayout isLoggedIn={isLoggedIn}>
            <div className='content'>
                <h1>Thanks!</h1>
                <p>Thank you for referring a friend to Modelteam. Please share the following link with your friends.</p>
                <p>For every friend that signs up and uploads their profile, you will git 5 ModelTeam tokens that can be used towards viewing others profile</p>
                <CopyURLToClipBoard urlText={referUrl} />
            </div>
        </EngLayout>
    );
};

export default Refer;