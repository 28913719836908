// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OrgLayout from '../components/OrgLayout';
import ProfileTab from '../components/ProfileTab';
import UserCohortSelect from '../components/UserCohortSelect';
import '../styles/jobs.css';
import '../styles/profile.css';
import '../styles/root.css';
import '../styles/search.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isOrgTokenExpired, ORG_PREFIX } from '../utils/CheckToken';

const teamPrefix = '/api/v1/org/team';
const teamsApiPrefix = teamPrefix + '/teams';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const OrgTeamProf = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [orgAccountUserEmailHash, setOrgAccountUserEmailHash] = useState(localStorage.getItem(ORG_PREFIX + 'email_hash'));
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(-1);
    const [teams, setTeams] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [currTeamHash, setCurrTeamHash] = useState(null);
    const [selectedTeamMemberIndex, setSelectedTeamMemberIndex] = useState('c-0-');
    const [currUserHash, setCurrUserHash] = useState(null);
    const [companyProfile, setCompanyProfile] = useState(null);
    const [l1, setL1] = useState(null);
    const [l2, setL2] = useState(null);
    const [l3, setL3] = useState(null);
    const [baselines, setBaselines] = useState(null);
    const [baselineAccountInfos, setBaselineAccountInfos] = useState(null);
    const [includeCompany, setIncludeCompany] = useState(false);

    const handleCheckboxChange = () => {
        setIncludeCompany(!includeCompany);
    };

    // Cache for profiles. Key will be team_hash + user_hash
    const profilesCache = {};


    useEffect(() => {
        setIsLoggedIn(!isOrgTokenExpired());
        setIsLoginChecked(true);
    }, []);

    useEffect(() => {
        if (l2 && l3) {
            setBaselines([l2['display_profile'], l3['display_profile']]);
            setBaselineAccountInfos([l2['account_info'], l3['account_info']]);
        } else if (l2) {
            setBaselines([l2['display_profile']]);
            setBaselineAccountInfos([l2['account_info']]);
        } else {
            setBaselines(null);
            setBaselineAccountInfos(null);
        }
    }, [l2, l3]);

    useEffect(() => {
        loadTeams();
        setCurrTeamHash(null);
        loadCompanyProfile();
    }, [orgAccountUserEmailHash]);

    useEffect(() => {
        if (!currUserHash || !currUserHash.display_profile) {
            return;
        }
        const langs = {};
        const skills = {};
        currUserHash.display_profile.langs.forEach((item, index) => {
            const key = item['_key'];
            if (index < 10) {
                langs[key] = 1
            }
            else {
                langs[key] = 0
            }
        });
        currUserHash.display_profile.skills.forEach((item, index) => {
            const key = item['_key'];
            if (index < 10) {
                skills[key] = 1
            }
            else {
                skills[key] = 0
            }
        });
    }, [currUserHash]);

    useEffect(() => {
        if (!currTeamHash) {
            setTeamMembers([]);
            setCurrUserHash(null);
            return;
        }
        loadTeamMembers(currTeamHash)
    }, [currTeamHash]);

    useEffect(() => {
        updateProfile();
    }, [currTeamHash, currUserHash, includeCompany]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/org/login?redirect=/org/teamprof`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadCompanyProfile = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamPrefix}/company`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data) {
            setCompanyProfile(data);
            setCurrTeamHash(data.team_hash);
        }
    };

    const loadTeamMembers = async (teamHash) => {
        if (!teamHash) {
            return;
        }
        // Get team members and cohorts with status >= 2
        const response = await fetchAPIWithToken(navigate, `${teamPrefix}/members?team_hash=${teamHash}&minstatus=2`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        const members = data.users
        const cohorts = data.cohorts
        if (members.length > 0) {
            setTeamMembers(members);
            if (cohorts.length == 0) {
                setSelectedTeamMemberIndex('u-0-');
                setCurrUserHash(members[0].user_hash);
            }
        } else {
            setTeamMembers([]);
            setCurrUserHash(null);
        }
        if (cohorts.length > 0) {
            setCohorts(cohorts);
            // Cohort will be selected by default if present
            setSelectedTeamMemberIndex('c-0-');
            setCurrUserHash(cohorts[0].user_hash);
        } else {
            setCohorts([]);
            setCurrUserHash(null);
        }
    };

    const loadTeams = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamsApiPrefix}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setTeams(data);
            if (selectedTeamIndex === -1) {
                setSelectedTeamIndex(0);
                setCurrTeamHash(data[0].team_hash);
            }
        } else {
            setTeams([]);
            setCurrTeamHash(null);
        }
    };

    const handleTeamSelectChange = (event) => {
        const index = event.target.value;
        if (!index || index === -1) {
            return;
        }
        setSelectedTeamIndex(index);
        setCurrTeamHash(teams[index].team_hash);
    };

    const handleTeamMemberSelectChange = (selectedValue) => {
        setSelectedTeamMemberIndex(selectedValue);
        const index = selectedValue.split('-')[1];
        if (selectedValue.startsWith('u-')) {
            setCurrUserHash(teamMembers[index].user_hash);
        } else if (selectedValue.startsWith('c-')) {
            setCurrUserHash(cohorts[index].user_hash);
        }
    };

    const updateProfile = async () => {
        if (!currTeamHash && !currUserHash) {
            return;
        }
        if (!companyProfile || selectedTeamIndex === -1) {
            return;
        }
        let tp = null;
        let up = null;
        const companyUserKey = companyProfile.team_hash + companyProfile.user_hash;
        let userKey = null;
        if (currTeamHash) {
            if (currUserHash) {
                userKey = currTeamHash + currUserHash;
                if (!profilesCache[userKey]) {
                    const response = await fetchAPIWithToken(navigate, `${teamPrefix}/member?team_hash=${currTeamHash}&user_hash=${currUserHash}`, 'GET', {});
                    if (response.status !== 200) {
                        console.error(response);
                        return;
                    }
                    const data = await response.json();
                    profilesCache[userKey] = data;
                }
                if (userKey !== companyUserKey) {
                    up = profilesCache[userKey];
                }
            }
            if (!profilesCache[currTeamHash]) {
                const response = await fetchAPIWithToken(navigate, `${teamPrefix}/teamavg?team_hash=${currTeamHash}&team_name=${teams[selectedTeamIndex].team_name}`, 'GET', {});
                if (response.status !== 200) {
                    console.error(response);
                    return;
                }
                const data = await response.json();
                profilesCache[currTeamHash] = data;
            }
            const teamUserKey = currTeamHash + profilesCache[currTeamHash].user_hash;
            if (teamUserKey !== companyUserKey && teamUserKey !== userKey) {
                tp = profilesCache[currTeamHash];
            }
        }
        let l1 = null;
        let l2 = null;
        let l3 = null;
        if (up) {
            l1 = up;
            l2 = tp ? tp : companyProfile;
            l3 = (includeCompany && tp) ? companyProfile : null;
        } else if (tp) {
            l1 = tp;
            l2 = companyProfile;
        } else {
            l1 = companyProfile;
        }
        setL1(l1);
        setL2(l2);
        setL3(l3);
    };

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }
    return (
        <OrgLayout isLoggedIn={isLoggedIn}>
            <div className='team-prof'>
                <div className='team-prof-header'>
                    <div>
                        <label>Company/Team*</label>
                        <select onChange={handleTeamSelectChange} value={selectedTeamIndex}>
                            <option value="">Select a Team</option>
                            {teams.map((item, index) => (
                                <option key={index} value={index}>
                                    {item.team_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <UserCohortSelect teamMembers={teamMembers} cohorts={cohorts} handleTeamMemberSelectChange={handleTeamMemberSelectChange} suffix={''} defVal={selectedTeamMemberIndex} />
                    <label>
                        <input
                            type="checkbox"
                            checked={includeCompany}
                            onChange={handleCheckboxChange}
                        />
                        Include Company Baseline
                    </label>
                </div>
                {l1 && <ProfileTab profileData={l1['display_profile']} accountInfo={l1['account_info']} baselines={baselines} baselineAccountInfos={baselineAccountInfos} />}
            </div>
        </OrgLayout >);
};

export default OrgTeamProf;
