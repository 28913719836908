import React from 'react';
import Login from '../components/LogIn';
import '../styles/login.css';
import '../styles/root.css';


const UserLogin = () => {
    return (
        <Login isOrg={false} />
    );
};

export default UserLogin;
