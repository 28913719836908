import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/modelteam_logo.png';
import '../styles/login.css';
import '../styles/root.css';
import { fetchAPI } from '../utils/ApiUtils';
import { isOrgTokenExpired, isTokenExpired, ORG_PREFIX, saveOrgToken, saveToken } from '../utils/CheckToken';
import LogInLayout from './LogInLayout';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const Login = ({ isOrg }) => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendTimer, setResendTimer] = useState(30); // Cooldown period in seconds
    const navigate = useNavigate();
    const query = useQuery();
    const newUserHomePage = isOrg ? '/org/account' : '/account';
    let redirectUrl = query.get('redirect');
    const referrer = query.get('ref') || '';
    const applyJob = query.get('applyto') || '';
    const atsCandidate = query.get('atsid') || '';
    const existingUserHome = isOrg ? '/org/jobs' : (applyJob ? '/account' : '/profile');

    useEffect(() => {
        if (isOrg ? !isOrgTokenExpired(true) : !isTokenExpired(true)) {
            saveApplyJob();
            navigate(redirectUrl ? redirectUrl : existingUserHome);
        } else if (isOrg ? !isOrgTokenExpired() : !isTokenExpired()) {
            navigate(redirectUrl ? redirectUrl : newUserHomePage);
        }
    }, []);

    useEffect(() => {
        let interval = null;
        if (isResendDisabled) {
            interval = setInterval(() => {
                setResendTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsResendDisabled(false);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isResendDisabled]);


    const handleLogin = async (e) => {
        e.preventDefault();

        setOtpSent(true);
        localStorage.clear();
        const api = '/api/v1/auth/send_otp'
        fetchAPI(api, 'POST', { email: email, is_org: isOrg }).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    setOtpSent(false);
                    const error_message = data['error']
                    console.error(error_message);
                    alert(error_message);
                });
            }
        }).catch(error => {
            // Handle error here
            console.error('Error:', error);
        });
    };

    const saveApplyJob = () => {
        if (applyJob && !isOrg) {
            localStorage.setItem('applyto', applyJob);
            localStorage.setItem('atsid', atsCandidate);
        }
    }

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        // Validate OTP (You should add your validation logic here)
        if (otp.length !== 6 || !/^\d+$/.test(otp)) {
            alert('Please enter a valid 6-digit OTP.');
            return;
        }
        const api = '/api/v1/auth/validate_otp';
        fetchAPI(api, 'POST', { email: email, otp: otp, is_org: isOrg }).then(response => {
            const org_prefix = isOrg ? ORG_PREFIX : '';
            if (response.ok) {
                saveApplyJob();
                localStorage.setItem(org_prefix + 'email', email);
                response.json().then(response_data => {
                    if ('email_hash' in response_data) {
                        // existing user
                        localStorage.setItem(org_prefix + 'email_hash', response_data['email_hash']);
                        if (!redirectUrl || redirectUrl === newUserHomePage) {
                            redirectUrl = existingUserHome;
                        }
                    } else {
                        if (referrer) {
                            localStorage.setItem(org_prefix + 'ref', referrer);
                        }
                        // new user redirect to account page always
                        redirectUrl = newUserHomePage;
                    }
                    if (isOrg) {
                        saveOrgToken(response_data);
                    } else {
                        saveToken(response_data);
                    }
                }).then(() => {
                    navigate(redirectUrl);
                });
            } else {
                response.json().then(data => {
                    const error_message = data['error']
                    console.error(error_message);
                    alert(error_message);
                });
            }
        })
    };

    const handleResendOtp = async () => {
        setIsResendDisabled(true);
        setResendTimer(30); // Reset the timer to 30 seconds

        const api = '/api/v1/auth/send_otp'
        setOtpSent(true);
        fetchAPI(api, 'POST', { email: email, is_org: isOrg }).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    const error_message = data['error']
                    setOtpSent(false);
                    console.error(error_message);
                    alert(error_message);
                });
            } else {
                alert('A new OTP has been sent to your email.');
            }
        }).catch(error => {
            // Handle error here
            console.error('Error:', error);
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (!otpSent) {
                handleLogin(event);
            }
            else {
                handleOtpSubmit(event);
            }
        }
    };

    const isDemoAccount = (email) => {
        return email === "demo@modelteam.ai" || email === "acme-demo@modelteam.ai";
    }

    return (
        <LogInLayout>
            <div className="login-box">
                <div className='login-logo-container'><img src={logo} alt="ModelTeam.ai" className='small-logo' /></div>
                <div className='login-title'>Log In</div>
                {isOrg ? <div className='login-subtitle'>Welcome to ModelTeam Enterprise&trade;</div>
                    : <div className='login-subtitle'>Grow your career with AI-verified skill profiles</div>}
                {!otpSent ?
                    <div className='login-form-container'>
                        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" onKeyDown={handleKeyDown} />
                        <button className='button-cta' onClick={handleLogin}>Send code</button>
                        {
                            isOrg ?
                                <div className='login-footer'>Are you looking to access your personal profile? <a href='/login'>Login Here</a></div> :
                                <div className='login-footer'>Are you looking to access your Enterprise Account? <a href='/org/login'>Login Here</a></div>
                        }
                    </div>
                    : <div className='login-form-container'><input type={isDemoAccount(email) ? "password" : "text"} id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter email verification code" onKeyDown={handleKeyDown} />
                        <div className='login-otp-info'>
                            <div>Code expired after 10 minutes.</div>
                            <div><a href="#" onClick={handleResendOtp} aria-disabled={isResendDisabled ? 'true' : 'false'}>{isResendDisabled ? `Resend OTP in ${resendTimer}s` : `Resend OTP`}</a></div>
                        </div>
                        <button className='button-cta' onClick={handleOtpSubmit}>Verify code</button>
                    </div>
                }
            </div>
        </LogInLayout>
    );
};

export default Login;
