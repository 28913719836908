import React, { useEffect, useState } from 'react';
import '../styles/TagInput.css';
import '../styles/root.css';

const TagInput = ({ onTagsChange, currVal }) => {
    const [inputValue, setInputValue] = useState("");
    const [tags, setTags] = useState(currVal || []);

    useEffect(() => {
        if (tags !== currVal) {
            onTagsChange(tags); // Update parent component whenever tags change
        }
    }, [tags, onTagsChange]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyDown = (event) => {
        if ((event.key === 'Enter' || event.key === ',') && inputValue.trim()) {
            event.preventDefault();
            setTags([...tags, inputValue.trim()]);
            setInputValue("");
        }
    };

    const handleRemoveTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder="Type and press Enter or Comma to add"
                className="input-field"
            />

            <div className="tags-container">
                {tags.map((tag, index) => (
                    <div key={index} className="tag">
                        <span className="tag-text">{tag}</span>
                        <button onClick={() => handleRemoveTag(index)} className="remove-button">
                            &times;
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TagInput;
