// Background.jsx

import React from 'react';
import '../styles/background.css';

const Background = () => {
  return (
    <div className="background">

    </div>
  );
}

export default Background;
