// src/Account.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CopyURLToClipBoard from '../components/CopyToClipBoard';
import EngLayout from '../components/EngLayout';
import Geo from '../components/Geo';
import '../styles/account.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isTokenExpired, saveToken } from '../utils/CheckToken';
import { getProfileUrl } from '../utils/Utils';


const userApiPrefix = '/api/v1/user/account';

const Account = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [emailHash, setEmailHash] = useState('');
    const [profileUrl, setProfileUrl] = useState('');
    const [status, setStatus] = useState('');
    const [statusColor, setStatusColor] = useState('orange');
    const [linkStatus, setLinkStatus] = useState('');
    const [linkStatusColor, setLinkStatusColor] = useState('orange');
    const [jobApplied, setJobApplied] = useState(false);
    const [whoAmI, setWhoAmI] = useState({
        email_hash: '',
        first_name: '',
        last_name: '',
        personal_email: '',
        about_me: '',
        linkedin_url: '',
        city: '',
        state: '',
        country: '',
        updated_at: '',
        skill_profile_status: '',
        referrer_id: '',
        deleted: ''
    });
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setWhoAmI(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const setGeo = (geo) => {
        setWhoAmI(prevState => ({
            ...prevState,
            ...geo
        }));
    };

    useEffect(() => {
        // New users will not have email_hash in local storage
        setIsLoggedIn(!isTokenExpired(false));
        setIsLoginChecked(true);
        const localEmail = localStorage.getItem('email');
        const emailHash = localStorage.getItem('email_hash');
        if (emailHash) {
            setEmailHash(emailHash);
            loadWhoAmI().then(() => {
                setIsEditing(false);
            });
        } else {
            setEmailHash('');
            whoAmI['personal_email'] = localEmail;
            const ref = localStorage.getItem('ref');
            whoAmI['referrer_id'] = ref ? ref : '';
            setIsEditing(true);
        }
    }, [navigate]);

    useEffect(() => {
        setLinkStatusAndColor(whoAmI.skill_profile_status);
        setStatusAndColor(whoAmI.skill_profile_status);
    }, [whoAmI.skill_profile_status, profileUrl]);

    useEffect(() => {
        if (emailHash) {
            applyToJob(true);
            setProfileUrl(getProfileUrl(emailHash));
        }
    }, [emailHash]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/login?redirect=/account`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const applyToJob = async (gotoJobs) => {
        const jobId = localStorage.getItem('applyto');
        if (!jobId || jobApplied) {
            return;
        }
        const atsCandidate = localStorage.getItem('atsid');
        const response = await fetchAPIWithToken(navigate, `/api/v1/user/apply_job`, 'POST', { job_hash: jobId, ats_id: atsCandidate });
        if (response.status === 201) {
            localStorage.removeItem('applyto');
            localStorage.removeItem('atsid');
            setJobApplied(true);
            alert('Applied to job successfully');
            if (gotoJobs) {
                navigate('/myjobs');
            }
        } else {
            console.error(response);
            alert('Error applying to job. Check myjobs page to see if you have already applied');
        }
    };

    const loadWhoAmI = async () => {
        const response = await fetchAPIWithToken(navigate, `${userApiPrefix}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            setEmailHash('');
            return;
        }
        const data = await response.json();
        setWhoAmI(data);
    };

    function validateUserAccount() {
        if (!whoAmI.first_name || !whoAmI.last_name || !whoAmI.about_me || !whoAmI.country || !whoAmI.linkedin_url) {
            alert('Please fill in all required fields');
            return false;
        }
        if (!emailHash && !document.getElementById('tos').checked) {
            alert('Please agree to the terms of service and privacy statement');
            return false;
        }
        return true;
    };

    const createOrUpdateAccount = async () => {
        if (!validateUserAccount(whoAmI)) {
            return;
        }
        if (emailHash) {
            const response = await fetchAPIWithToken(navigate, userApiPrefix, 'PUT', whoAmI);
            if (response.status === 200) {
                const data = await response.json();
                setWhoAmI(data);
                setIsEditing(false);
            }
            else {
                console.error(response);
            }
        } else {
            const response = await fetchAPIWithToken(navigate, userApiPrefix, 'POST', whoAmI);
            if (response.status === 201) {
                const data = await response.json();
                // Logged in user will have new token issued once the account is created
                saveToken(data);
                alert("Your account has been created. You can now start building your skill profile.");
                await applyToJob(false);
                navigate('/experience');
            } else {
                alert('Error creating account');
                console.error(response);
            }
        }
    };

    const toggleDelete = async () => {
        if (!whoAmI.deleted) {
            const confirmation = prompt('We will miss you :(\nType "delete my account" to confirm deletion. This will mark your account as deleted and your profile will not be accessible.');
            if (confirmation === 'delete my account') {
                whoAmI.deleted = true;
            } else {
                alert('Account deletion not confirmed');
                return;
            }
        } else {
            whoAmI.deleted = false;
        }
        await createOrUpdateAccount();
    };

    function setStatusAndColor(status) {
        switch (status) {
            case 1:
                setStatusColor('orange');
                setStatus('In Progress.  Its contents will be updated once the profile is ready.');
                break;
            case 2:
                setStatusColor('green');
                setStatus('Ready. Feel free to share your profile with others.');
                break;
            case -1:
                setStatusColor('red');
                setStatus('Error. Contact us for assistance.');
                break;
            default:
                setStatusColor('orange');
                setStatus('Not Ready');
        }
    }

    function setLinkStatusAndColor(status) {
        if (profileUrl) {
            switch (status) {
                case '':
                    setLinkStatusColor('red');
                    setLinkStatus('No Link. Create your account to get the profile link')
                    break;
                case 2:
                    setLinkStatusColor('green');
                    setLinkStatus('Good to share');
                    break;
                case -1:
                    setLinkStatusColor('red');
                    setLinkStatus('Error. Contact us for assistance');
                    break;
                default:
                    setLinkStatusColor('green');
                    setLinkStatus('Ok to share');
            }
        } else {
            setLinkStatusColor('red');
            setLinkStatus('No Link. Create your account to get the profile link')
        }
    }

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <EngLayout isLoggedIn={isLoggedIn && emailHash}>
            <div className='outer-box'>
                <div className='right-pane'>
                    <h2>Hello {whoAmI.first_name ? whoAmI.first_name + '!' : 'World! Please create your account'}!</h2>
                    <div className='whoami'>
                        <label htmlFor="first_name">First Name</label>
                        <input type="text" id="first_name" name="first_name" required readOnly={!isEditing} value={whoAmI.first_name} onChange={handleChange} />
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text" id="last_name" name="last_name" required readOnly={!isEditing} value={whoAmI.last_name} onChange={handleChange} />
                        <label htmlFor="about_me">About Me</label>
                        <textarea id="about_me" name="about_me" rows="3" required readOnly={!isEditing} value={whoAmI.about_me || ''} onChange={handleChange}></textarea>
                        <label htmlFor="linkedin_url">LinkedIn URL</label>
                        <input type="text" id="linkedin_url" name="linkedin_url" readOnly={!isEditing} value={whoAmI.linkedin_url || ''} onChange={handleChange} />
                        <Geo setGeo={setGeo} isEditing={isEditing} currGeo={whoAmI} />
                        <label>{'ModelTeam Profile Status as of ' + whoAmI.updated_at}</label>
                        <label style={{ fontSize: "large" }}>Link: <label style={{ fontSize: "large", color: linkStatusColor }}>{linkStatus}</label></label>
                            <label style={{ fontSize: "large" }}>Content: <label style={{ fontSize: "large", color: statusColor }}>{status}</label></label>
                        {profileUrl &&
                            <CopyURLToClipBoard urlText={profileUrl} />
                        }

                    </div>
                    {!emailHash &&
                        <div>
                            <h4>
                                <input type="checkbox" id="tos" name="tos" required readOnly={!isEditing} title='Please' />
                                By creating an account, you agree to our <a href='https://modelteam.ai/#privacy' target='_blank' rel='noreferrer'>Privacy Statement</a> and <a href='https://modelteam.ai/#terms' target='_blank' rel='noreferrer'>Terms of Service</a>
                            </h4>
                        </div>
                    }
                    <div className='col_buttons'>
                        {isEditing &&
                            <button id="cancel" onClick={() => {
                                setIsEditing(false);
                                loadWhoAmI();
                            }}>Cancel</button>
                        }
                        {!isEditing &&
                            <button id="edit" onClick={() => setIsEditing(true)} disabled={whoAmI.deleted}>Edit</button>
                        }
                        {isEditing &&
                            <button onClick={createOrUpdateAccount}>Submit</button>
                        }
                        {!isEditing &&
                            <button className={whoAmI.deleted ? '' : 'red'} onClick={toggleDelete}>{whoAmI.deleted ? 'Add Back' : 'Delete'}</button>
                        }
                    </div>
                </div>
            </div>
        </EngLayout>
    );
};

export default Account;
