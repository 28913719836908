import React, { useEffect, useState } from 'react';
const UserCohortSelect = ({ teamMembers, cohorts, handleTeamMemberSelectChange, suffix, defVal }) => {
    const [selectedTeamMemberIndex, setSelectedTeamMemberIndex] = useState(defVal);
    const handleChange = (e) => {
        setSelectedTeamMemberIndex(e.target.value);
        handleTeamMemberSelectChange(e.target.value);
    };
    useEffect(() => {
        setSelectedTeamMemberIndex(defVal);
    }, [defVal]);

    return (<div>
        <label>{suffix ? suffix + ' ' : ''}Cohort/User</label>
        <select onChange={handleChange} value={selectedTeamMemberIndex}>
            <option value="">Select a Cohort/User</option>
            <optgroup label="Cohorts">
                {cohorts.map((item, index) => (
                    <option key={`c-${index}-${suffix}`} value={`c-${index}-${suffix}`}>
                        {item.user_email}
                    </option>
                ))}
            </optgroup>
            <optgroup label="Users">
                {teamMembers.map((item, index) => (
                    <option key={`u-${index}-${suffix}`} value={`u-${index}-${suffix}`}>
                        {item.user_email}
                    </option>
                ))}
            </optgroup>
        </select>
    </div>
    );
};

export default UserCohortSelect;