// src/Jobs.js
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/modelteam_logo_light.svg';
import CertSummary from '../components/CertSummary';
import CheckboxGroup from '../components/CheckBoxGroup';
import Layout from '../components/Layout';
import { DomainSkills, ProgLangs } from '../components/Skills';
import '../styles/root.css';
import '../styles/summary.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isTokenExpired } from '../utils/CheckToken';
import { getProfileUrl } from '../utils/Utils';


const ProfileSummary = () => {
    const contentRef = useRef();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [infoData, setInfoData] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [skillProfileStatus, setSkillProfileStatus] = useState(0);
    const [currUserLangs, setCurrUserLangs] = useState({});
    const [currUserSkills, setCurrUserSkills] = useState({});
    const [filteredProfile, setFilteredProfile] = useState(null);
    const MAX_LANGS = 2;
    const MAX_SKILLS = 4;

    const profileApiPrefix = '/api/v1/user/profile';

    const update = async () => {
        if (!profileData) {
            return;
        }
        // filter the display profile to only include the selected languages and skills
        const langs = Object.keys(currUserLangs).filter((key) => currUserLangs[key] === 1);
        const skills = Object.keys(currUserSkills).filter((key) => currUserSkills[key] === 1);
        const filteredProfile = {
            langs: profileData.langs.filter((item) => langs.includes(item['_key'])),
            skills: profileData.skills.filter((item) => skills.includes(item['_key'])),
        };
        setFilteredProfile(filteredProfile);
    };

    useEffect(() => {
        setIsLoggedIn(!isTokenExpired());
        setIsLoginChecked(true);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            fetchAccount();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/login?redirect=/summary`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    useEffect(() => {
        if (currUserLangs && currUserSkills) {
            update();
        }
    }, [currUserLangs, currUserSkills]);

    const handleCheckboxChange = (group, key) => {
        if (group === 'langs') {
            setCurrUserLangs((prevState) => ({
                ...prevState,
                [key]: prevState[key] === 1 ? 0 : 1,
            }));
        } else if (group === 'skills') {
            setCurrUserSkills((prevState) => ({
                ...prevState,
                [key]: prevState[key] === 1 ? 0 : 1,
            }));
        }
    };

    async function fetchAccount() {
        const emailHash = localStorage.getItem('email_hash');
        setProfileId(emailHash);
        const response = await fetchAPIWithToken(navigate, `${profileApiPrefix}?profile_id=${emailHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        setProfileData(data['display_profile']);
        setInfoData(data['account_info']);
        setSkillProfileStatus(data['skill_profile_status']);
        const langs = {};
        const skills = {};
        data['display_profile'].langs.forEach((item, index) => {
            const key = item['_key'];
            if (index < MAX_LANGS) {
                langs[key] = 1
            }
            else {
                langs[key] = 0
            }
        });
        data['display_profile'].skills.forEach((item, index) => {
            const key = item['_key'];
            if (index < MAX_SKILLS) {
                skills[key] = 1
            }
            else {
                skills[key] = 0
            }
        });
        setCurrUserLangs(langs);
        setCurrUserSkills(skills);
        update();
    }

    const downloadCertAsPDF = () => {
        const element = contentRef.current;

        html2canvas(element, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            const pdf = new jsPDF({
                orientation: 'protrait',
                unit: 'px',
                format: [canvas.width, canvas.height],
            });

            pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
            const linkText = `View & Verify Full Profile: (Sign up or log in to your modelteam.ai account)`;
            const linkX = 30;
            const linkY = canvas.height - 40;
            pdf.text(linkText, linkX, linkY);
            pdf.setTextColor(0, 0, 255);
            pdf.text(getProfileUrl(profileId), linkX + 10, linkY + 20);
            pdf.save('modelteamProfile.pdf');
        });
    };

    const displayCert = () => {
        const today = new Date();
        const currYear = today.getFullYear();
        const currMonth = today.getMonth() + 1;
        const expYear = currYear + 1;
        const profileUrl = getProfileUrl(profileId);
        const urlEncodedProfileUrl = encodeURIComponent(profileUrl);
        const liCertUrl = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=ModelTeam%20AI%20Verified%20Skills&organizationId=99187668&issueYear=${currYear}&issueMonth=${currMonth}&expirationYear=${expYear}&expirationMonth=${currMonth}&certUrl=${urlEncodedProfileUrl}&certId=${profileId}`;
        return (
            <div className='outer-box-cert'>
                <div className='left-pane-cert'>
                    <div>
                        <CheckboxGroup
                            data={currUserLangs}
                            displayText="Languages (Max 2)"
                            groupName="langs"
                            onChange={handleCheckboxChange}
                            maxSelection={2}
                        />
                        <CheckboxGroup
                            data={currUserSkills}
                            displayText="Tops Skills (Max 4)"
                            groupName="skills"
                            onChange={handleCheckboxChange}
                            maxSelection={4}
                        />
                    </div>
                    <div className='pair-td'>
                        <button className='small_button' onClick={downloadCertAsPDF}>Download as PDF</button>
                        <a href={liCertUrl} target="_blank" rel="noreferrer">
                            <img src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png" alt="LinkedIn Add to Profile button" />
                        </a>
                    </div>
                </div>
                <div className='right-pane-cert'>
                    <div className='cert-container' ref={contentRef}>
                        <div className='cert-logo-background'>
                            <img src={logo} alt="ModelTeam.ai" className="cert-logo" />
                        </div>
                        {filteredProfile && <div className='legend_container'>
                            <div className='legend'>
                                <CertSummary accountInfo={infoData} profileData={profileData} />
                            </div>
                            <div className="cert-box">
                                <ProgLangs langsData={filteredProfile.langs} numYears={1} accountInfo={infoData} isCertificate={true} />
                                <DomainSkills skillsData={filteredProfile.skills} numYears={1} isCertificate={true} />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    return (
        <Layout>
            {skillProfileStatus === 2 ? displayCert() : null}
        </Layout>
    );

};

export default ProfileSummary;
