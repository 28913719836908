// Layout.jsx

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/root.css';
import '../styles/ftu-step.css';
import userIcon from '../assets/icons/user-03.svg';
import briefIcon from '../assets/icons/briefcase-01.svg';
import codeIcon from '../assets/icons/code-02.svg';
import modelteamIcon from '../assets/icons/modelteam-logo.svg';

const FTULayout = ({ step, children }) => {

  return (
    <div className="content">
    <Header />
    <div className='ftu-container'>
        <div className='ftu-step-container'>
            <div key='1' className='ftu-step'>
                <div className='ftu-step-icon-line'>
                    <div className={`ftu-step-icon ${step === '1' ? 'active' : ''}`}><img src={userIcon} alt='acct'/></div>
                    <div className={`ftu-step-line ${step === '1' ? 'active' : ''}`}></div>
                </div>
                <div className='ftu-step-text'>
                    <div className={`ftu-step-title ${step === '1' ? 'active' : ''}`}>Create your account</div>
                    <div className={`ftu-step-subtitle ${step === '1' ? 'active' : ''}`}>Provide your email and basic info</div>
                </div>
            </div>
            <div key='2' className='ftu-step'>
                <div className='ftu-step-icon-line'>
                    <div className={`ftu-step-icon ${step === '2' ? 'active' : ''}`}><img src={briefIcon} alt='exp'/></div>
                    <div className={`ftu-step-line ${step === '2' ? 'active' : ''}`}></div>
                </div>
                <div className='ftu-step-text'>
                    <div className={`ftu-step-title ${step === '2' ? 'active' : ''}`}>Add an experience</div>
                    <div className={`ftu-step-subtitle ${step === '2' ? 'active' : ''}`}>Add a project or job to your profile</div>
                </div>
            </div>
            <div key='3' className='ftu-step'>
                <div className='ftu-step-icon-line'>
                    <div className={`ftu-step-icon ${step === '3' ? 'active' : ''}`}><img src={codeIcon} alt='code' /></div>
                    <div className={`ftu-step-line ${step === '3' ? 'active' : ''}`}></div>
                </div>
                <div className='ftu-step-text'>
                    <div className={`ftu-step-title ${step === '3' ? 'active' : ''}`}>Analyze your code</div>
                    <div className={`ftu-step-subtitle ${step === '3' ? 'active' : ''}`}>Download a model to analyze your code</div>
                </div>
            </div>
            <div key='4' className='ftu-step'>
                <div className='ftu-step-icon-line'>
                    <div className={`ftu-step-icon ${step === '4' ? 'active' : ''}`}><img src={modelteamIcon} alt='submit' /></div>
                </div>
                <div className='ftu-step-text'>
                    <div className={`ftu-step-title ${step === '4' ? 'active' : ''}`}>Submit your skill stats file</div>
                    <div className={`ftu-step-subtitle ${step === '4' ? 'active' : ''}`}>Upload your skill stats for evaluation</div>
                </div>
            </div>
        </div>
        <div className='ftu-content-container'>
            <div className='ftu-form-card'>
                {children}
            </div>
        </div>
    </div>
    <Footer />
    </div>
  );
}

export default FTULayout;
