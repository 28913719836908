import React, { useEffect, useRef, useState } from 'react';
import { DomainSkills, DomainSkillsWithBaseline, ProgLangs, ProgLangsWithBaseline } from '../components/Skills';
import '../styles/profile.css';
import '../styles/root.css';
import Tab from './Tab';


const TabContent = ({ activeTab, profileData, numYears, baselines, accountInfo, baselineAccountInfos, recipeSkillsAndLangs }) => {
  if (activeTab === 'Skills') {
    if (baselines) {
      return <DomainSkillsWithBaseline skillsData={profileData.skills} numYears={numYears} baselines={baselines ? baselines.map(baseline => baseline.skills) : null} accountInfo={accountInfo} baselineAccountInfos={baselineAccountInfos} priorityList={recipeSkillsAndLangs} />;
    } else {
      return <DomainSkills skillsData={profileData.skills} numYears={numYears} accountInfo={accountInfo} />;
    }
  }
  if (activeTab === 'Languages') {
    if (baselines) {
      return <ProgLangsWithBaseline langsData={profileData.langs} numYears={numYears} baselines={baselines ? baselines.map(baseline => baseline.langs) : null} accountInfo={accountInfo} baselineAccountInfos={baselineAccountInfos} priorityList={recipeSkillsAndLangs} />;
    } else {
      return <ProgLangs langsData={profileData.langs} numYears={numYears} accountInfo={accountInfo} />;
    }
  }
  if (activeTab === 'Verify') {
    return <div className='verification-container'>
      <table className="table-with-border">
        <thead>
          <tr>
            <th>Company</th>
            <th>GitID</th>
          </tr>
        </thead>
        <tbody>
          {profileData.verification.map((verification, index) => (
            <tr key={index}>
              {/* workEmail, company, gitID */}
              <td>{verification[1]}</td>
              <td>{verification[2]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>;
  }
  return null;
};

const ProfileTab = ({ profileData, baselines, accountInfo, baselineAccountInfos, numYears = 3, recipeSkillsAndLangs }) => {
  const [activeTab, setActiveTab] = useState('Languages');
  const [selectedOption, setSelectedOption] = useState(numYears);
  const outerDivRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (outerDivRef.current) {
        if (outerDivRef.current.scrollTop === 0) {
          setIsAtTop(true);
        } else {
          setIsAtTop(false);
        }
      }
    };

    const outerDiv = outerDivRef.current;
    if (outerDiv) {
      outerDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (outerDiv) {
        outerDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function isAllVerified() {
    return false;
  }

  return (
    <div className="profile-tabs-container">
      <div className="profile-tabs-bar">
        {accountInfo ? <div className="profile-header">
          {isAtTop ? null : accountInfo.first_name} {isAtTop ? null : accountInfo.last_name}
        </div> : <div className='profile-header'></div>}
        <div className="profile-tabs">
          <Tab name="Langs" isActive={activeTab === 'Languages'} onClick={() => setActiveTab('Languages')} />
          <Tab name="Skills" isActive={activeTab === 'Skills'} onClick={() => setActiveTab('Skills')} />
          {profileData.verification && <Tab name={"Verify" + (isAllVerified() ? '' : ' ⚠')} isActive={activeTab === 'Verify'} onClick={() => setActiveTab('Verify')} />}
          <div className="dropdown-container">
            <label className='whoami'>Showing</label>
            <select id="dropdown" name='dropdown' value={selectedOption} onChange={handleChange} className='custom-select'>
              <option value="1">1</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="10">10</option>
            </select>
            <label className='whoami'>Years</label>
          </div>
        </div>
      </div>
      <div className="profile-charts" ref={outerDivRef}>
        <TabContent activeTab={activeTab} profileData={profileData} numYears={Math.round(selectedOption)} baselines={baselines} accountInfo={accountInfo} baselineAccountInfos={baselineAccountInfos} recipeSkillsAndLangs={recipeSkillsAndLangs} />
      </div>
    </div>
  );
}

export default ProfileTab;
