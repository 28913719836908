// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MultiSelect from '../components/MultiSelect';
import OrgLayout from '../components/OrgLayout';
import TagInput from '../components/TagInput';
import '../styles/account.css';
import '../styles/jobs.css';
import '../styles/org.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isOrgTokenExpired, ORG_PREFIX } from '../utils/CheckToken';
import { formatDate } from '../utils/DateTimeUtils';
const teamPrefix = '/api/v1/org/team';
const teamsApiPrefix = teamPrefix + '/teams';
const teamApiPrefix = teamPrefix + '/team';
const teamApiWGPrefix = teamPrefix + '/teamwg';
const cohortApiPrefix = teamPrefix + '/cohort';

const OrgTeams = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [teamHash, setTeamHash] = useState(sessionStorage.getItem(ORG_PREFIX + 'team_hash') || '');
    const [orgAccountUserEmailHash, setOrgAccountUserEmailHash] = useState(localStorage.getItem(ORG_PREFIX + 'email_hash'));
    const [teams, setTeams] = useState([]);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(-1);
    const [teamMembers, setTeamMembers] = useState([]);
    const [cohortMembers, setCohortMembers] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [selectedCohortIndex, setSelectedCohortIndex] = useState(-1);
    const [addNew, setAddNew] = useState(false);
    const [editTeam, setEditTeam] = useState(false);
    const [reloadTeams, setReloadTeams] = useState(false);
    const [addNewCohort, setAddNewCohort] = useState(false);
    const [reloadTeam, setReloadTeam] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [selectedOption, setSelectedOption] = useState('DIY');
    const [repos, setRepos] = useState([]);
    const [currCohort, setCurrCohort] = useState({
        user_hash: 'test',
        user_email: '',
        cohort_members: [],
        is_cohort: true
    });
    const [currTeam, setCurrTeam] = useState({
        org_email_hash: '',
        team_name: '',
        team_hash: '',
        skill_profile_name: '',
        repo_list: [],
        display_profile: '',
        skill_profile_status: 0,
        deleted: false,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrTeam(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if (editTeam) {
            if (currTeam.repo_list && currTeam.repo_list.length > 0 && !currTeam.skill_profile_name) {
                setSelectedOption('White Glove');
            } else if ((!currTeam.repo_list || currTeam.repo_list.length === 0) && currTeam.skill_profile_name) {
                setSelectedOption('DIY');
            }
        }
    }, [editTeam]);

    useEffect(() => {
        setIsLoggedIn(!isOrgTokenExpired());
        setIsLoginChecked(true);
    }, []);

    useEffect(() => {
        loadTeams();
        currTeam['org_email_hash'] = orgAccountUserEmailHash;
    }, [navigate, reloadTeams]);

    useEffect(() => {
        loadTeam();
        loadTeamMembers(teamHash);
        setAddNewCohort(false);
        if (teamHash) {
            sessionStorage.setItem(ORG_PREFIX + 'team_hash', teamHash);
        }
    }, [teamHash, reloadTeam]);

    useEffect(() => {
        if (currCohort && currCohort.cohort_members) {
            setCohortMembers(currCohort.cohort_members);
        }
    }, [currCohort]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/org/login?redirect=/org/teams`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadTeam = async () => {
        if (teamHash) {
            const response = await fetchAPIWithToken(navigate, `${teamApiPrefix}?team_hash=${teamHash}`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const data = await response.json();
            setCurrTeam(data);
            if (data.repo_list) {
                setRepos(data.repo_list);
            }
        } else {
            setCurrTeam({
                org_email_hash: orgAccountUserEmailHash,
                team_name: '',
                team_hash: '',
                skill_profile_name: '',
                display_profile: '',
                skill_profile_status: 0,
                deleted: false,
            });
        }
    }

    const loadTeamMembers = async (teamHash) => {
        if (!teamHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamPrefix}/members?team_hash=${teamHash}&minstatus=1`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        const members = data.users
        const cohorts = data.cohorts
        if (members.length > 0) {
            setTeamMembers(members);
        } else {
            setTeamMembers([]);
        }
        if (cohorts.length > 0) {
            setCohorts(cohorts);
            setSelectedCohortIndex(0);
            setCurrCohort(cohorts[0]);
        } else {
            setCohorts([]);
            setCurrCohort(null);
        }
    };

    const loadTeams = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, teamsApiPrefix, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setTeams(data);
            if (selectedTeamIndex === -1 && !teamHash) {
                setSelectedTeamIndex(0);
                setTeamHash(data[0].team_hash);
                setCurrTeam(data[0]);
            } else if (teamHash) {
                const index = data.findIndex(team => team.team_hash === teamHash);
                if (index !== -1) {
                    setSelectedTeamIndex(index);
                    setCurrTeam(data[index]);
                } else {
                    setSelectedTeamIndex(0);
                    setTeamHash(data[0].team_hash);
                    setCurrTeam(data[0]);
                }
            }
        } else {
            setTeams([]);
            setCurrTeam({
                org_email_hash: orgAccountUserEmailHash,
                team_name: '',
                team_hash: '',
                skill_profile_name: '',
                repo_list: [],
                merged_profile: '',
                skill_profile_status: 0,
                deleted: false,
            });
        }
    };

    function validateTeam() {
        const fileInput = document.getElementById('profile');
        // skill profile is optional while creating team
        if (currTeam.team_name && (!teamHash || (fileInput && fileInput.files.length > 0))) {
            return true;
        }
        return false;
    }

    const deleteTeam = async () => {
        if (teamHash) {
            if (!currTeam.deleted) {
                if (!window.confirm('Are you sure you want to delete this team?. This can\'t be undone.')) {
                    return;
                }
            }
            const response = await fetchAPIWithToken(navigate, teamApiPrefix + '?team_hash=' + teamHash, 'DELETE', currTeam);
            if (response.status === 200) {
                setReloadTeams(!reloadTeams);
            } else {
                console.error(response);
            }
        }
    }

    const createOrUpdateTeam = async () => {
        if (validateTeam()) {
            const fileInput = document.getElementById('profile');
            const formData = new FormData();
            formData.append('org_email_hash', currTeam.org_email_hash);
            formData.append('team_name', currTeam.team_name);
            if (fileInput && fileInput.files.length > 0) {
                const fileName = fileInput.files[0].name;
                if (fileName.endsWith('.gz') && (fileName.startsWith('mt_stats.json') || fileName.startsWith('mt_profile.json'))) {
                    formData.append('file', fileInput.files[0]);
                    if (teamHash) {
                        formData.append('team_hash', teamHash);
                    }
                } else {
                    console.error('Invalid file format');
                    alert('Invalid file format');
                    return;
                }
            }
            try {
                const response = await fetchAPIWithToken(navigate, teamApiPrefix, teamHash ? 'PUT' : 'POST', formData, true, true);
                if (response.status !== 201 && response.status !== 200) {
                    console.error(response);
                    teamHash ? alert('Error updating team') : alert('Error creating team');
                    return;
                }
                if (!teamHash) {
                    const data = await response.json();
                    setTeamHash(data.team_hash);
                    setAddNew(false);
                } else {
                    setEditTeam(false);
                }
                setReloadTeams(!reloadTeams);
                fileInput.value = '';
                teamHash ? alert('Team updated successfully. Profile will be updated Shortly (<30 mins)') : alert('Team created successfully' + (fileInput.files.length > 0 ? '. Profile will be ready shortly' : ''));
            } catch (error) {
                console.error(error);
                alert('Error uploading file');
            }
        } else {
            alert('Enter All Fields. Skill Profile is optional only while creating a new team');
            console.error("No file selected.");
        }
    }

    const validateTeamWG = () => {
        if (currTeam.team_name && repos.length > 0) {
            return true;
        }
        return false;
    }

    const createOrUpdateTeamWG = async () => {
        if (validateTeamWG()) {
            try {
                currTeam['repo_list'] = repos;
                const response = await fetchAPIWithToken(navigate, teamApiWGPrefix, teamHash ? 'PUT' : 'POST', currTeam);
                if (response.status !== 201 && response.status !== 200) {
                    console.error(response);
                    teamHash ? alert('Error updating team') : alert('Error creating team');
                    return;
                }
                if (!teamHash) {
                    const data = await response.json();
                    setTeamHash(data.team_hash);
                    setAddNew(false);
                } else {
                    setEditTeam(false);
                }
                setReloadTeams(!reloadTeams);
                alert('Success! Profile will be ready to view shortly.');
            } catch (error) {
                console.error(error);
                alert('Error uploading file');
            }
        } else {
            alert('Please fill all fields');
        }
    }


    const explainStatus = (name, status) => {
        let statusText = <h3 style={{ color: 'red' }}>No Profile</h3>;
        switch (status) {
            case 1:
                statusText = <h3 style={{ color: 'orange' }}>Profile Uploaded. AI Processing</h3>;
                break;
            case 2:
                statusText = <h3 style={{ color: 'green' }}>Profile Ready</h3>;
                break;
            default:
                statusText = <h3 style={{ color: 'red' }}>No Profile</h3>;
        }
        return <div className='two_columns'><h4>Profile {name} Status: </h4>{statusText}</div>;
    }

    const handleCohortChange = (event) => {
        const index = event.target.value;
        setSelectedCohortIndex(index);
        setCurrCohort(cohorts[index]);
    }

    const handleCohortMemSelect = (selectedIndices) => {
        const selectedMembers = selectedIndices.map(index => teamMembers[index]);
        setCohortMembers(selectedMembers);
    }

    const addNewCohortHandler = async () => {
        const user_email = document.getElementById('cohort').value;
        if (user_email && cohortMembers.length > 0) {
            const cohortMembersLite = cohortMembers.map(member => {
                return {
                    user_email: member.user_email,
                    user_hash: member.user_hash
                }
            });
            const cohort = {
                user_email: user_email,
                org_email_hash: orgAccountUserEmailHash,
                team_hash: teamHash,
                is_cohort: true,
                cohort_members: cohortMembersLite
            };
            const response = await fetchAPIWithToken(navigate, cohortApiPrefix, 'POST', cohort);
            if (response.status === 201) {
                setReloadTeam(!reloadTeam);
            } else {
                alert('Error creating cohort');
                setCohortMembers([]);
                console.error(response);
            }
            setAddNewCohort(false);
        }
    }

    const handleFileChange = (event) => {
        setFileSelected(event.target.files.length > 0);
    };

    const deleteCohort = async () => {
        if (selectedCohortIndex !== -1) {
            // check if cohort is not average_of_all_users. thats builtin cohort
            if (currCohort.user_email === 'average_of_all_users') {
                alert('Cannot delete this cohort');
                return;
            }
            const response = await fetchAPIWithToken(navigate, cohortApiPrefix + '?user_hash=' + currCohort.user_hash, 'DELETE', currCohort);
            if (response.status === 200) {
                setReloadTeam(!reloadTeam);
                alert('Cohort deleted');
            } else {
                console.error(response);
                alert('Error deleting cohort');
            }
        }
    }

    const displayCohorts = () => {
        if (cohorts.length > 0) {
            return <div>
                <div className='two_columns'>
                    <div className='whoami'>
                        <label htmlFor='cohort'>Cohorts</label>
                        <select id='cohort' name='cohort' onChange={handleCohortChange} value={selectedCohortIndex} size={5} className='select_cohort'>
                            {cohorts.map((cohort, index) => (
                                <option key={index} value={index}>{cohort.user_email}{cohort.skill_profile_status === 2 ? ' (Ready) ' : ' (In Progress) '}</option>
                            ))}
                        </select>
                    </div>
                    <div className='whoami'>
                        <label htmlFor='members'>Members</label>
                        <select id='members' name='members' readOnly={true} size={5} className='select_cohort'>
                            {cohortMembers.map((member, index) => (
                                <option key={index} value={index}>{member.user_email}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='two_buttons'>
                    <button onClick={() => setAddNewCohort(true)} className='small_button'>Add New Cohort</button>
                    <button onClick={deleteCohort} className='red' disabled={selectedCohortIndex === -1}>Delete Cohort</button>
                </div>
            </div >;
        } else if (currTeam && currTeam.skill_profile_status === 2) {
            return <div className='whoami'>
                <h4>No Cohorts</h4>
                <button onClick={() => setAddNewCohort(true)} className='small_button'>Add New Cohort</button>
            </div>;
        }
    }

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const editTeamProfile = () => {
        setEditTeam(true);
    };

    const addNewTeam = () => {
        let disableDIY = false;
        let disableWG = false;
        if (editTeam) {
            if (currTeam.repo_list && currTeam.repo_list.length > 0 && !currTeam.skill_profile_name) {
                disableDIY = true;
            } else if ((!currTeam.repo_list || currTeam.repo_list.length === 0) && currTeam.skill_profile_name) {
                disableWG = true;
            }
        }

        return (
            <div className='right-pane-teams'>
                <div className='two_columns'>
                    <button onClick={() => handleOptionChange('DIY')} className={`job_div ${selectedOption === 'DIY' ? 'selected' : ''}`} disabled={disableDIY}>
                        DIY
                    </button>
                    <button onClick={() => handleOptionChange('White Glove')} className={`job_div ${selectedOption === 'White Glove' ? 'selected' : ''}`} disabled={disableWG}>
                        White Glove
                    </button>
                </div>

                {selectedOption === 'DIY' && (
                    <div className='team-container'>
                        <p>Follow the instructions in our <a href='https://github.com/modelteam-ai/modelteam.ai/blob/main/README_org.md' target='_blank' rel="noreferrer">Git README</a> to generate stats to build your team profile. The team profile will be built by our AI system from those stats.</p>
                        <span>
                            For smaller teams (&lt;10), you can generate stats for the entire team in your laptop.<br />
                            For larger teams, we recommend running on a dedicated server or generate stats for a subset of the team.
                            <pre>
                                python gen_team_git_stats.py -r &lt;repo_list&gt; [-g "&lt;gitid1&gt;,&lt;gitid2&gt;,..."] -t "team_name" [-n &lt;number_of_years&gt;]
                            </pre>
                            You can get <strong>Git Id</strong> by running <code>git log</code> command in your git repo as shown below. Text between <code>&lt;&gt;</code> is the <strong>Git Id</strong>, e.g., <code>Author: XYZ &lt;<strong>userXYZ@org.ai</strong>&gt;</code>
                            <br /><br />
                        </span>
                        <div className='whoami'>
                            <label htmlFor='team_name'>Team Name (cannot be modified after creation)</label>
                            <input type='text' id='team_name' name='team_name' placeholder='Team Name' value={currTeam.team_name} readOnly={!addNew} onChange={handleChange} />
                        </div>
                        <div className='whoami'>
                            <label htmlFor='profile'>Team Stats (mt_stats.json.gz) (Optional & Can be updated later)</label>
                            <input id='profile' type='file' name='profile' accept='.gz' onChange={handleFileChange} />
                        </div>
                        <div className='two_buttons'>
                            <button onClick={createOrUpdateTeam} className='small_button'>Submit</button>
                            <button onClick={() => { setEditTeam(false); setAddNew(false) }} className='small_button'>Cancel</button>
                        </div>
                    </div>
                )}

                {selectedOption === 'White Glove' && (
                    <div className='team-container'>
                        <p>
                            Please invite our <a href='https://github.com/modelteam-ai/modelteam.ai/blob/main/README_org_wg.md#modelteamai-bots' target='_blank' rel="noreferrer">bot</a> as a collobarotor to your repositories and we will build a profile for you.
                            You will be notified once the profile is ready.
                        </p>
                        <div className='whoami'>
                            <label htmlFor='team_name'>Team Name (cannot be modified after creation)</label>
                            <input type='text' id='team_name' name='team_name' placeholder='Team Name' value={currTeam.team_name} readOnly={!addNew} onChange={handleChange} />
                            <label htmlFor='repo_list'>Repo URL List</label>
                            <TagInput onTagsChange={setRepos} currVal={repos} />
                        </div>
                        <div className='two_buttons'>
                            <button onClick={createOrUpdateTeamWG} disabled={repos.length <= 0} className='small_button'>Submit</button>
                            <button onClick={() => { setEditTeam(false); setAddNew(false) }} className='small_button'>Cancel</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
    const displayTeam = () => {
        const isCompany = teams[selectedTeamIndex] && teams[selectedTeamIndex]['is_company'];
        if (addNew || editTeam) {
            return addNewTeam();
        }
        if (teamHash) {
            if (addNewCohort) {
                return <div className='right-pane-teams'>
                    <div className='two_columns'>
                        <div>
                            <label htmlFor='cohort'>Cohort Name</label>
                            <input type='text' id='cohort' name='cohort' placeholder='Cohort Name' />
                            <label htmlFor='members'>Members</label>
                            <MultiSelect items={teamMembers} maxSel={20} onSelectChange={handleCohortMemSelect} itemKey='user_email' />
                        </div>
                        <div className='two_buttons'>
                            <button onClick={addNewCohortHandler} className='small_button'>Submit</button>
                            <button onClick={() => setAddNewCohort(false)} className='small_button'>Cancel</button>
                        </div>
                    </div>
                </div>
            }
            else {
                return <div className='right-pane-teams'>
                    <div className='whoami'>
                        {explainStatus(currTeam.skill_profile_name, currTeam.skill_profile_status)}
                        <label>(Newly updated profiles & New Cohorts will take around 30 minutes to be processed by our backend system)</label>
                        <div className='three_buttons'>
                            <button className='small_button' onClick={editTeamProfile} disabled={isCompany}>Upload New Profile</button>
                            <button className='red' onClick={deleteTeam} disabled={isCompany}>Delete</button>
                            <button className='small_button' onClick={() => navigate('/org/teamprof')} disabled={currTeam.skill_profile_status !== 2}>View Team Profiles</button>
                        </div>
                    </div>
                    {displayCohorts()}
                </div>
            }
        } else {
            return <div className='right-pane-teams'></div>
        }
    }

    const handleTeamClick = (index, clickedTeamHash) => {
        setSelectedTeamIndex(index);
        setTeamHash(clickedTeamHash);
        setAddNew(false);
    }

    const handleAddNew = () => {
        setSelectedTeamIndex(-1);
        setTeamHash('');
        setAddNew(true);
        setRepos([]);
    }

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <OrgLayout isLoggedIn={isLoggedIn}>
            <div className='outer-box-top'>
                <div className='left-pane-teams'>
                    {teams.map((team, index) => (
                        <div key={index} className={`job_div ${(selectedTeamIndex === index && !addNew) ? 'selected' : ''}`} onClick={() => handleTeamClick(index, team.team_hash)}>
                            <div>
                                <b>{team.team_name}</b>
                                <br />
                                <span>Updated on: {formatDate(team.updated_at)}</span>
                            </div>
                        </div>
                    ))}
                    <button onClick={() => handleAddNew()} className={'job_div selected'}>
                        <b>Add New Team Profile</b>
                    </button>
                </div>
                {displayTeam()}
            </div>
        </OrgLayout>
    );
};

export default OrgTeams;
