// src/utils/checkToken.js
const ORG_PREFIX = 'org_';

const checkTokenExpired = (tokenExpiry, emailHash, isOrg, checkForEmailHash, orgExpected) => {
  // Check if token or tokenExpiry is missing, or if emailHash is needed and missing
  if (isOrg !== orgExpected || !tokenExpiry || (checkForEmailHash && !emailHash)) {
    return true;
  }

  // Convert tokenExpiry to a number (milliseconds since epoch)
  const tokenExpiryNum = Number(tokenExpiry) * 1000;

  // Check for NaN values in tokenExpiryNum
  if (isNaN(tokenExpiryNum)) {
    return true;
  }

  // Get the current time in milliseconds
  const now = Date.now();

  // Check if the current time is past the token's expiry time
  return now > tokenExpiryNum;
};

const isTokenExpired = (checkForEmailHash = true) => {
  const tokenExpiry = localStorage.getItem('jwt_expiry');
  const emailHash = localStorage.getItem('email_hash');
  const isOrg = localStorage.getItem('is_org') === 'true';
  return checkTokenExpired(tokenExpiry, emailHash, isOrg, checkForEmailHash, false);
};

const isOrgTokenExpired = (checkForEmailHash = true) => {
  const tokenExpiry = localStorage.getItem(ORG_PREFIX + 'jwt_expiry');
  const emailHash = localStorage.getItem(ORG_PREFIX + 'email_hash');
  const isOrg = localStorage.getItem(ORG_PREFIX + 'is_org') === 'true';
  return checkTokenExpired(tokenExpiry, emailHash, isOrg, checkForEmailHash, true);
};

function saveToken(response) {
  localStorage.setItem('jwt_expiry', response['access_token_expires']);
  localStorage.setItem('is_org', response['is_org']);
  if (response['email_hash']) {
    localStorage.setItem('email_hash', response['email_hash']);
  }
}

function saveOrgToken(response) {
  localStorage.setItem(ORG_PREFIX + 'jwt_expiry', response['access_token_expires']);
  localStorage.setItem(ORG_PREFIX + 'is_org', response['is_org']);
  if (response['email_hash']) {
    localStorage.setItem(ORG_PREFIX + 'email_hash', response['email_hash']);
  }
}


export { ORG_PREFIX, isOrgTokenExpired, isTokenExpired, saveOrgToken, saveToken };
