import { frontendUrl } from '../configs/config';

export function toTitleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
export const arrToMap = (arr) => {
    const res = {};
    if (!arr) {
        return res;
    }
    for (let i = 0; i < arr.length; i++) {
        res[arr[i]['_key']] = arr[i];
    }
    return res;
};

export const getProfileUrl = (profileId) => {
    return `${frontendUrl}/profile?id=${profileId}`
}

export function trimString(str, length = 10) {
    if (str.length > length) {
        return str.substring(0, length - 3) + '...';
    }
    return str;
}

