// src/Account.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EngLayout from '../components/EngLayout';
import '../styles/profile.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isTokenExpired } from '../utils/CheckToken';
import { formatDate } from '../utils/DateTimeUtils';


const userApiPrefix = '/api/v1/user/jobs';

const MyJobs = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    // org_name, job_name, applied_on
    const [myJobs, setMyJobs] = useState([]);

    useEffect(() => {
        setIsLoggedIn(!isTokenExpired());
        setIsLoginChecked(true);
    }, [navigate]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/login?redirect=/myjobs`);
        }
        if (isLoggedIn) {
            loadMyJobs();
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadMyJobs = async () => {
        const response = await fetchAPIWithToken(navigate, `${userApiPrefix}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        setMyJobs(data);
    };

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <EngLayout isLoggedIn={isLoggedIn}>
            <div className='whoami'>
                <b>My Active Job Applications</b>
                <p>Hiring Teams that use modelteam.ai will send a unique URL for you to add your profile to their job. Once you apply, hiring team can see your profile and it will show up in this list (if the opening is still active).</p>
            </div>
            {myJobs.length > 0 && <div className='outer-box'>
                <table className='table-with-border'>
                    <thead>
                        <tr>
                            <th>Organization</th>
                            <th>Job</th>
                            <th>Job Id</th>
                            <th>Applied On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {myJobs.map((job, index) => (
                            <tr key={index}>
                                <td>{job.org_name}</td>
                                <td>{job.job_name}</td>
                                <td>{job.job_hash}</td>
                                <td>{formatDate(job.created_at)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </EngLayout>
    );
};

export default MyJobs;
