// src/CreateAccount.js
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import FTULayout from '../components/FTULayout';
import Geo from '../components/Geo';
import '../styles/ftu-step.css';
import '../styles/root.css';


const userApiPrefix = '/api/v1/user/account';

const CreateAccount = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName]   = useState('');
    const [email, setEmail]         = useState('');
    const [city, setCity]           = useState('');
    const [state, setState]         = useState('');
    const [country, setCountry]     = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);

    // Additional state for form submission feedback
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors]       = useState({});

    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange  = (e) => setLastName(e.target.value);
    const handleEmailChange     = (e) => setEmail(e.target.value);
    const handleCityChange      = (e) => setCity(e.target.value);
    const handleStateChange     = (e) => setState(e.target.value);
    const handleCountryChange   = (e) => setCountry(e.target.value);
    const handleAgreeTermsChange = (e) => setAgreeTerms(e.target.checked);

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Basic form validation
        const validationErrors = {};
        if (!firstName) validationErrors.firstName = 'First name is required';
        if (!lastName)  validationErrors.lastName = 'Last name is required';
        if (!email)     validationErrors.email = 'Email is required';
        if (!agreeTerms) validationErrors.agreeTerms = 'You must agree to the terms';
      
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
      
        // If validation passes
        const formData = {
            firstName,
            lastName,
            email,
            city,
            state,
            country,
        };
      
        // Simulate form submission
        console.log('Form Data:', formData);

        // Send form data to the server
        const response = await fetch(userApiPrefix, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            console.error('Failed to submit form:', response);
            return;
        }
      
        // Provide user feedback
        setSubmitted(true);
    };  

  
    return (
        <FTULayout step='1' >
            <div className='ftu-title'>Create your account</div>
            <div className='ftu-subtitle'>Help us create an accurate profile for you</div>
            <form onSubmit={handleSubmit}>
                <div className='ftu-form-row'>
                    <div>
                    <label>First Name:</label>
                    <input type="text" value={firstName} onChange={handleFirstNameChange} required />
                    </div>
                    <div>
                    <label>Last Name:</label>
                    <input type="text" value={lastName} onChange={handleLastNameChange} required />
                    </div>
                </div>
                <div className='ftu-form-row'>
                    <div>
                    <label>Email:</label>
                    <input type="email" value={email} onChange={handleEmailChange} required />
                    </div>
                </div>
                <div className='ftu-form-row'>
                <div>
                    <label>City:</label>
                    <input type="text" value={city} onChange={handleCityChange} />
                    </div>
                    <div>
                    <label>State:</label>
                    <input type="text" value={state} onChange={handleStateChange} />
                    </div>
                </div>
                <div>
                <label>Country:</label>
                <input type="text" value={state} onChange={handleCountryChange} />
                </div>
                <div>
                <label>
                    <input type="checkbox" checked={agreeTerms} onChange={handleAgreeTermsChange} />
                    I agree to the Terms of Service
                </label>
                </div>
                <button type="submit" disabled={!agreeTerms}>Submit</button>
                {submitted && <p>Form submitted successfully!</p>}
            </form>
        </FTULayout>
    );
};

export default CreateAccount;
