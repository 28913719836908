import React from 'react';
import '../styles/BlurredContent.css';
const BlurredContent = ({ children, message }) => {
    return (
        <div className="blurred-content-wrapper">
            <div className="blurred-content">{children}</div>
            <div className="blur-message">
                {message || 'Login to view full profile'}
            </div>
        </div>
    );
};

export default BlurredContent;
